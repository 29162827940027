import { BrModal, BrRadioGroup, BrTextarea, Typography } from '@govbr-ds/react-components';
import { MotivoCancelamento, Requisicao } from '../../types/dtos';
import lang from '../../lang';
import { ChangeEvent, useEffect, useState } from 'react';
import requisicoesService from '../../service/requisicoesService';
import { usePageContext } from '../../context/pageContext';
import popup, { PopupMessage } from '../../common/popup';
import '../../style/modal.css'

const ModalCancelamento = (props: { requisicao: Requisicao, onDismiss: (refresh: boolean) => void }) => {
    const { setShowPopup } = usePageContext();

    const [requisicao] = useState<Requisicao>(props.requisicao)
    const [motivo, setMotivo] = useState<Optional<MotivoCancelamento>>()
    const [motivosList, setMotivosList] = useState<Optional<MotivoCancelamento[]>>()
    const [outroMotivo, setOutroMotivo] = useState<Optional<string>>()

    useEffect(() => {
        fetchMotivos()
    }, [])

    useEffect(() => {
        if (motivo && !motivo.apresentarDescricaoMotivo) {
            setOutroMotivo(undefined)
        }
    }, [motivosList, motivo])

    const fetchMotivos = () => {
        requisicoesService.motivosCancelamento().then((resp) => {
            setMotivosList(resp)
        })
    }

    const onMotivoSelected = (event: ChangeEvent<HTMLInputElement>) => {
        let id = event.target.value
        let selected = motivosList?.find(m => m.id === id)
        setMotivo(selected)
    }

    const onDescriptionChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let text = event.target.value
        setOutroMotivo(text)
    }

    const submit = () => {
        // @TODO VALIDAR
        if (motivo) {
            requisicoesService.cancelar(requisicao.id, motivo?.id ?? null, outroMotivo ?? null).then((resp) => {
                let popupMsg: PopupMessage = resp
                    ? popup.successConfig(lang.tr('cancela_requisicao_ok'))
                    : popup.errorConfig(lang.tr('cancela_requisicao_erro'))
                setShowPopup(popupMsg)
                props.onDismiss(true)
            })
        }
    }

    const RadioGroup = () => {
        let group: Object[] = []
        motivosList?.forEach(item => {
            group.push({ id: item.id, label: item.nome, value: item.id })
        });

        return (
            <BrRadioGroup name={'motivoCancel'}
                title={lang.tr('motivo_cancelamento')}
                options={group}
                value={motivo?.id}
                onChange={(evt) => onMotivoSelected(evt)} />
        )
    }

    return (
        <BrModal title={lang.tr('cancelar_requisicao')}
            minWidth={500}
            isOpen={true}
            secondaryAction={{ label: lang.tr('cancelar'), action: () => props.onDismiss(false) }}
            primaryAction={{ label: lang.tr('confirmar'), action: () => submit() }}
            onClose={() => props.onDismiss(false)}>
            {
                <div className='modal-content'>
                    <br />
                    <Typography size="up-01">
                        {lang.tr('certeza_cancelar_requisicao')}
                    </Typography>
                    <Typography weight="semi-bold" size="up-01">
                        {requisicao.numero}?
                    </Typography>
                    <br />
                    {RadioGroup()}
                    <br />
                    {motivo && motivo.apresentarDescricaoMotivo
                        ? <BrTextarea id="textarea-id1"
                            label={''}
                            placeholder={lang.tr('descricao')}
                            onChange={(evt) => onDescriptionChanged(evt)}
                        ></BrTextarea>
                        : null
                    }
                </div>
            }
        </BrModal>
    );
};

export default ModalCancelamento;
