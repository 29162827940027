import { BrColors } from '../../types/colors';
import { ReclamacaoStatus } from '../../types/enums';
import ReclamacoesPage from './ReclamacoesPage';

export const getTagColor = (status: string) => {
    switch (status) {
        case ReclamacaoStatus.CONCLUIDO:
            return BrColors.info;
        case ReclamacaoStatus.PENDENTE:
            return BrColors.warning;
        case ReclamacaoStatus.EM_ATENDIMENTO:
            return BrColors.support_01;
        case ReclamacaoStatus.ATENDIDO:
            return BrColors.highlight;
        case ReclamacaoStatus.NAO_ATENDIDO:
            return BrColors.support_03;
        case ReclamacaoStatus.RESPONDIDO:
            return BrColors.highlight;
        case ReclamacaoStatus.INFORMACAO_SOLICITADA:
            return BrColors.support_07;
        case ReclamacaoStatus.INFORMACAO_ENVIADA:
            return BrColors.support_08;
        default:
            return BrColors.secondary_07;
    }
};

export default ReclamacoesPage;
