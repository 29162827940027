import { useEffect, createContext, useContext, useState } from "react";
import { NavigateOptions, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Page } from "../types/page";
import AppRoutes from '../api/app-routes';
import { useAuthContext } from "./useAuth";
import storage from "./storage";
import { PopupMessage } from "../common/popup";
import Toast from "../components/Toast";
import { RestoreNav } from "../types/restoreNav";

export type PageContextType = {
    openPage: (page: Page, options?: NavigateOptions) => void;
    pageParams: () => Object;
    showPopup: Optional<PopupMessage>;
    setShowPopup: (msg: Optional<PopupMessage>) => void;
};

export const PageContext = createContext<Nullable<PageContextType>>(null);
export const usePageContext = () => useContext(PageContext) as PageContextType;

const PageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    let [searchParams] = useSearchParams();
    const { authInfo, login } = useAuthContext();
    const [showPopup, setShowPopup] = useState<Optional<PopupMessage>>()

    useEffect(() => {
        window.addEventListener(storage.SESSION_INFO, () => {
            let info = storage.fetch(storage.SESSION_INFO);
            if (info) {
                openPage(Page.HOME, { replace: true })
            } else {
                openPage(Page.SPLASH, { replace: true })
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const code = searchParams.get('code')
        if (code && location.pathname === process.env.REACT_APP_URI_RETORNO_LOGIN) {
            login(code)
        } else {
            const restoreNav: Nullable<RestoreNav> = getRestoreNav()
            if (restoreNav?.reload) {
                setRestoreNav(false)
                navigate(restoreNav.pathname, {state: restoreNav.state})
            } else if (restoreNav?.key === location.key || location.key === "default") {
                setRestoreNav(true)
            } else if (location.pathname !== AppRoutes.getRoute(Page.SPLASH)) {
                setRestoreNav(false)
            }
        }
    }, [location, searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        clearHistory()
    }, [authInfo?.authenticated]) // eslint-disable-line react-hooks/exhaustive-deps

    function getRestoreNav(): Nullable<RestoreNav> {
        const restoreNav: Nullable<string> = storage.fetch(storage.NAVIGATION)
        if (restoreNav) {
            return JSON.parse(restoreNav) as RestoreNav
        }
        return null
    }

    function setRestoreNav(reload: boolean) {
        storage.set(storage.NAVIGATION, JSON.stringify({...location, reload: reload}))
    }

    function openPage(page: Page, options?: NavigateOptions) {
        if (page === Page.HOME) {
            clearHistory()
        }
        navigate(AppRoutes.getRoute(page), options);
    }

    function pageParams() {
        return location.state ? { ...location.state } : undefined
    }

    function clearHistory() {
        let page: Page = authInfo?.authenticated ? Page.HOME : Page.SPLASH;
        navigate(AppRoutes.getRoute(page))
    }

    return (
        <PageContext.Provider value={{ openPage, pageParams, showPopup, setShowPopup }}>
            {children}
            <Toast />
        </PageContext.Provider>
    );
}

export default PageProvider;