import React, { useState } from 'react';
import '../../style/list.css';
import { BrButton, BrCard, BrItem, Icon, Typography } from '@govbr-ds/react-components';
import { HistoricoTratamento, Metadado } from '../../types/dtos';
import lang from '../../lang';
import stringUtils from '../../utils/stringUtils';

interface ListHistoricoProps {
  historicoTratamento?: HistoricoTratamento;
}

const ListHistorico = ({
  historicoTratamento
}: ListHistoricoProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  
  return (
    <>
      <ul>
        <li className='card-title'>
          <Typography htmlElement='p' weight="semi-bold" className="mb-1n">
            {stringUtils.formatDate(historicoTratamento?.dataHora ?? "")}
          </Typography>
        </li>
        <BrCard>
          <li className='card-title'>
            <Typography htmlElement='p' weight="regular" className="mb-1n">{lang.tr('dado_pessoal_tratado')}</Typography>
            <BrButton onClick={toggleExpansion} circle={true}>
              <Icon icon={isExpanded ? "fa fa-angle-up" : "fas fa-angle-down"}></Icon>
            </BrButton>
          </li>
          {isExpanded && (
            <BrItem>
              {historicoTratamento?.metadadosTratados.map((item: Metadado, index: number) => {
                return (
                  <li className={item.sensivel ? "warning-after" : ''} key={item.id} >
                    {item.rotulo}
                  </li>
                )
              })}
            </BrItem>
          )}
        </BrCard>
      </ul>

    </>
  );
};

export default ListHistorico;
