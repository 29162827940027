import { useEffect, useState } from 'react';
import { BrSelect } from '@govbr-ds/react-components';
import { Page } from '../../types/page';
import PageComponent from '../../components/PageComponent';
import lang from '../../lang';
import TextMessage from '../../components/TextMessage';
import reclamacoesService from '../../service/reclamacoesService';
import { Controlador } from '../../types/dtos';
import ComplaintAgentCard from '../../components/ComplaintAgentCard';

const NovaReclamacaoPage = () => {
    const [controlador, setControlador] =
        useState<Optional<Controlador>>(undefined);

    const [controladores, setControladores] =
        useState<Optional<Controlador[]>>(undefined);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {

    }, [controlador])

    const fetch = async () => {
        reclamacoesService.getControladores().then((controladores) => {
            setControladores(controladores);
        });
    };

    const selectControlador = (name: string) => {
        for (const curr of controladores || []) {
            if (curr.nome === name) {
                setControlador(curr);
                break;
            }
        }
    };

    const getOptions = () => (controladores || []).map(({ nome }) => nome);

    return (
        <PageComponent page={Page.NOVA_RECLAMACAO}>
            <TextMessage
                type="info"
                message={lang.tr('nova_reclamacao_selecione_controlador')}
            />

            <BrSelect
                label={`${lang.tr('Controlador')}:`}
                options={getOptions()}
                placeholder={lang.tr('selecione_controlador')}
                value={lang.tr('selecione_controlador')}
                onClick={({ currentTarget: { value } }) => {
                    selectControlador(value);
                }}
            />

            {controlador && (
                <ComplaintAgentCard controlador={controlador} />
            )}
        </PageComponent>
    );
};

export default NovaReclamacaoPage;
