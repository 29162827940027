import { Requisicao } from '../../types/dtos';
import PaginatedList from '../../components/PaginatedList';
import RequisicaoItem from './RequisicaoItem';

interface RequisicoesMenuProps {
    requisicoes: Requisicao[];
    selected: Optional<Requisicao>;
    setRequisicao: (arg0: Requisicao) => void;
}

const RequisicoesMenu = ({
    requisicoes,
    selected,
    setRequisicao
}: RequisicoesMenuProps) => {
    const requestBuilder = (requisicao: Requisicao) => (
        <RequisicaoItem
            key={requisicao.id.toString()}
            requisicao={requisicao}
            selectedRequisicao={selected}
            setRequisicao={setRequisicao}
        />
    );

    return (
        <PaginatedList
            items={requisicoes}
            selectedItem={selected}
            setSelectedItem={setRequisicao}
            itemBuilder={requestBuilder}
        />
    );
};

export default RequisicoesMenu;
