import { BrCard, Col, Row } from '@govbr-ds/react-components';
import { MenuButton } from './Menu';
import { MouseEventHandler } from 'react';
import SessionCountdown from './SessionCountdown';
import ExitButton from './ExitButton';
import DefaultBreadcrumb from './DefaultBreadcrumb';
import { Page } from '../types/page';
import AppRoutes from '../api/app-routes';
import { Breadcrumb } from '../types/breadcrumb';

interface AppBarProps {
    page: Page;
    openMenu: MouseEventHandler;
}

const AppBar = ({ openMenu, page }: AppBarProps) => {
    const getCrumbs = (
        currPage: Page,
        crumbs: Breadcrumb[] = []
    ): Breadcrumb[] => {
        const isHome = currPage === Page.HOME;

        crumbs.unshift({
            page: currPage,
            title: AppRoutes.getTitle(currPage),
            isHome: isHome
        });

        return isHome
            ? crumbs
            : getCrumbs(AppRoutes.getPageOrigin(currPage), crumbs);
    };

    return (
        <BrCard>
            <Row justifyContent="between">
                <Col>
                    <MenuButton onClick={openMenu} />
                    <DefaultBreadcrumb
                        crumbs={getCrumbs(page)}
                    ></DefaultBreadcrumb>
                </Col>

                <Col className="collapsable" alignSelf="center" margin={2}>
                    <SessionCountdown className="mr-4" />
                    <ExitButton />
                </Col>
            </Row>
        </BrCard>
    );
};

export default AppBar;
