import { BrButton } from '@govbr-ds/react-components';
import lang from '../lang';
import { useAuthContext } from '../context/useAuth';

const ExitButton = () => {
    const { logout } = useAuthContext();


    return (
        <BrButton secondary={true} size="small" onClick={logout}>
            {lang.tr('sair')}
        </BrButton>
    )
}

export default ExitButton;
