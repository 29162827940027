import api from '../api';
import Assets from '../assets';
import { Controlador } from '../types/dtos';

const controladorService = {
    async getControlador(id: string): Promise<Nullable<Controlador>> {
        const resp = await api.getControlador(id);
        if (resp.success) {
            return resp.data as Controlador;
        }
        return null
    },

    async getControladores(): Promise<Controlador[]> {
        let resp = await api.getControladores();
        if (resp.success) {
            return resp.data as Controlador[]
        }
        return []
    },

    async getImagem(id: Optional<string>): Promise<string> {
        if (id) {
            const resp = await api.getImagemControlador(id);
            if (resp.success) {
                return resp.data as string;
            }
        }
        return Assets.SERPRO_LGPD_LOGO;
    },
}

export default controladorService;
