export enum StatusConsentimento {
    ATIVO = 'ATIVO',
    PENDENTE = 'PENDENTE',
    REVOGADO = 'REVOGADO',
    NEGADO = 'NEGADO',
    EXPIRADO = 'EXPIRADO'
}

export enum StatusRequisicao {
    PENDENTE = 'PENDENTE',
    CONCLUIDO = 'CONCLUIDO',
    CANCELADO = 'CANCELADO',
    EM_ATENDIMENTO = 'EM_ATENDIMENTO',
    ATENDIDO = 'ATENDIDO',
    NAO_ATENDIDO = 'NAO_ATENDIDO',
    RESPONDIDO = 'RESPONDIDO',
    INFORMACAO_SOLICITADA = 'INFORMACAO_SOLICITADA',
    INFORMACAO_ENVIADA = 'INFORMACAO_ENVIADA',
    ARQUIVADO = 'ARQUIVADO',
}

export enum ReclamacaoStatus {
    PENDENTE = 'PENDENTE',
    EM_ATENDIMENTO = 'EM_ATENDIMENTO',
    ATENDIDO = 'ATENDIDO',
    NAO_ATENDIDO = 'NAO_ATENDIDO',
    RESPONDIDO = 'RESPONDIDO',
    INFORMACAO_SOLICITADA = 'INFORMACAO_SOLICITADA',
    INFORMACAO_ENVIADA = 'INFORMACAO_ENVIADA',
    CONCLUIDO = 'CONCLUIDO',
    ARQUIVADO = 'ARQUIVADO',
}

export enum ManeirasObtencaoInformacao {
    PRESENCIALMENTE = 1,
    REQUISICAO_ELETRONICA = 2,
    OUTRO = 3,
}
