import { MessageType } from '@govbr-ds/react-components/dist/types/messages';

interface Props {
    message: string;
    type: MessageType;
    title?: string;
}

const TextMessage = ({ title, type, message }: Props) => (
    <div className={`br-message rounder-sm ${type}`}>
        <div className="content" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
            {title && <span className="message-title text-bold">{title}</span>}
            <p className="message-body text-medium">{message}</p>
        </div>
    </div>
);

export default TextMessage;
