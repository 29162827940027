import bullhorn from './bullhorn-solid.png'
import bsaLogo from './bsa-logo.png'
import checkCircleSolid from './check-circle-solid.png'
import consentimento from './consentimento.png'
import cvmLogo from './cvm-logo.jpg'
import database from './database-solid.png'
import govFederal from './governo-federal.png'
import generalContabilLogo from './general-contabil-logo.jpg'
import acessoInfo from './acesso-informacao.png'
import serproLgpdLogo from './serpro-lgpd.png'
import serproLgpdCidadao from './SerproLGPDPDCPortalCidadao.png'
import serproLgpdWhite from './SerproLGPDPDCPortalCidadao-white.png'
import serproLgpdWhiteBanner from './SerproLGPDPDCPortalCidadao-white-banner.png'
import serproLgpdHorizontal from './SerproLGPDmarcaHorizontal.png'
import serproLogo from './serpro-logo.jpg'
import vlibrasWhite from './vlibras_icon.png'
import pdcBackground from './background_final.jpg'
import contaGovBr from './conta_govbr_v2.jpg'
import banking from './InternetBanking-green.png'
import certDigital from './cert-digital.png'
import certNuvem from './CD-Nuvem.png'
import qrCode from './qrcode.png'
import question from './circle-question-solid.svg'
import idCard from './id-card-solid.png'
import blankSquare from './square1.png'
import warning from './icon-warning.png'
import info from './icon-info.png'
import success from './icon-success.png'
import error from './icon-error.png'
import cloudUpload from './icon-cloud-upload.png'
import trash from './icon-trash.png'
import close from './icon-close.png'

const Assets = {
    BULLHORN: bullhorn,
    BSA_LOGO: bsaLogo,
    BLANK_SQUARE: blankSquare,
    CHECK_CIRCLE_SOLID: checkCircleSolid,
    CONSENTIMENTO: consentimento,
    CVM_LOGO: cvmLogo,
    DATABASE: database,
    GOVERNO_FEDERAL: govFederal,
    GENERAL_CONTABIL_LOGO: generalContabilLogo,
    ACESSO_INFO: acessoInfo,
    SERPRO_LGPD_LOGO: serproLgpdLogo,
    SERPRO_LGPD_CIDADAO: serproLgpdCidadao,
    SERPRO_LGPD_WHITE: serproLgpdWhite,
    SERPRO_LGPD_WHITE_BANNER: serproLgpdWhiteBanner,
    SERPRO_LGPD_HORIZONTAL: serproLgpdHorizontal,
    SERPRO_LOGO: serproLogo,
    VLIBRAS_WHITE: vlibrasWhite,
    PDC_BACKGROUND: pdcBackground,
    CONTA_GOVBR: contaGovBr,
    BANKING: banking,
    CERT_DIGITAL: certDigital,
    CERT_NUVEM: certNuvem,
    QRCODE: qrCode,
    QUESTION: question,
    ID_CARD: idCard,
    WARNING: warning,
    INFO: info,
    SUCCESS: success,
    ERROR: error,
    CLOUD_UPLOAD: cloudUpload,
    TRASH: trash,
    CLOSE: close,
}

export default Assets;