import { Page } from '../types/page';
import PageObject from '../types/page-object';
import lang from '../lang';
import stringUtils from '../utils/stringUtils';

const param: string = '{param}';
const PARAM_LOGOUT_URI_RETORNO = 'post_logout_redirect_uri';
const DOMAIN_API = '/api';

const pages: Record<Page, PageObject> = {
    SPLASH: { route: '/', title: lang.tr('welcome'), icon: 'globe' },
    HOME: { route: '/home', title: lang.tr('homepage'), icon: 'home' },
    LOGIN: { route: process.env.REACT_APP_URI_RETORNO_LOGIN ?? '/', title: lang.tr('login'), icon: 'sign-in-alt' },
    LOGOUT: { route: process.env.REACT_APP_URI_RETORNO_LOGOUT ?? '/', title: lang.tr('sessao_encerrada'), icon: 'sign-out-alt' },
    CONSENTIMENTO: { route: '/consentimentos', title: lang.tr('consentimentos'), icon: 'user_shield'},
    INFO_TRATAMENTO: { route: '/tratamentos', title: lang.tr('tratamentos'), icon: 'database'  },
    RECLAMACOES: { route: '/reclamacoes', title: lang.tr('minhas_reclamacoes'), icon: 'bullhorn' },
    NOVA_RECLAMACAO: { route: '/reclamacao', title: lang.tr('nova_reclamacao'), icon: 'bullhorn', origin: Page.RECLAMACOES },
    NOVA_RECLAMACAO_FORM: { route: '/reclamacao-form', title: lang.tr('nova_reclamacao_form'), icon: 'bullhorn', origin: Page.NOVA_RECLAMACAO },
    REQUISICOES: { route: '/requisicoes', title: lang.tr('minhas_requisicoes'), icon: 'check-circle' },
    NOVA_REQUISICAO: { route: '/requisicao', title: lang.tr('nova_requisicao'), icon: 'check-circle', origin: Page.REQUISICOES },
    POLITICAS: { route: '/politicas', title: lang.tr('politicas_privacidade'), icon: 'file-alt' },
    INFO_TRATAMENTO_DETALHADO: { route: '/tratamentos/detalhado', title: lang.tr('detalhes'), icon: 'database', origin: Page.INFO_TRATAMENTO },
};

const AppRoutes = {
    pages,
    param,
    PARAM_LOGOUT_URI_RETORNO,

    URL_RETORNO_LOGIN: process.env.REACT_APP_URI_RETORNO_LOGIN,
    URL_RETORNO_LOGOUT: process.env.REACT_APP_URI_RETORNO_LOGOUT,

    URL_ACESSO_INFO: 'https://www.gov.br/acessoainformacao/pt-br',
    URL_BRASIL_GOVBR: 'http://www.brasil.gov.br/',
    URL_VLIBRAS: 'http://www.vlibras.gov.br/',
    URL_HELP: 'https://www.gov.br/governodigital/pt-br/conta-gov-br/ajuda-da-conta-gov.br',
    URL_TERMOS_USO: 'https://cadastro.acesso.gov.br/termo-de-uso',
    URI_AVISO_PRIVACIDADE: '/aviso_privacidade-pdc-resposta-ao-titular_v1.pdf',
    
    URL_AUTH: '/auth/endpoint',
    URL_LOGIN: '/auth/login',
    URL_LOGOUT: process.env.REACT_APP_ACESSO_LOGOUT ?? '',
    URL_UPDATE_TITULAR: '/',

    URL_TIPOS_REQUISICAO: '/requisicaoprivacidade/tiposrequisicao',
    URL_MOTIVOS_CANCELAMENTO: '/requisicaoprivacidade/motivoscancelamento',

    URL_CONTROLADOR: '/controlador',
    URL_CONTROLADOR_IMAGEM: '/controlador/imagem',
    URL_CONTROLADORES: '/controlador',

    URL_CONSENTIMENTOS: '/consentimento',
    URL_SUMARIO_CONSENTIMENTOS: '/consentimento/sumario',
    URL_CONSENTIMENTO_UPDATE: '/consentimento/updateStatus',

    URL_REQUISICOES: '/requisicaoprivacidade',
    URL_SUMARIO_REQUISICOES: '/requisicaoprivacidade/sumario',
    URL_REQUISICOES_CONTROLADORES: '/requisicaoprivacidade/controladores',
    URL_REQUISICAO_DOWNLOAD_PDF: '/requisicaoprivacidade/download-pdf',
    URL_REQUISICAO_DOWNLOAD_ANEXO_HIST: '/requisicaoprivacidade/' + param + '/historico/' + param + '/download',
    URL_AVALIAR_REQUISICAO: '/requisicaoprivacidade/avaliar',
    URL_DEVOLVER_REQUISICAO: '/requisicaoprivacidade/devolver',
    URL_CANCELAR_REQUISICAO: '/requisicaoprivacidade/cancelar',

    URL_RECLAMACOES: '/reclamacao',
    URL_SUMARIO_RECLAMACOES: '/reclamacao/sumario',
    URL_RECLAMACOES_CONTROLADORES: '/reclamacao/controladores',
    URL_DEVOLVER_RECLAMACAO: '/reclamacao/devolver',

    URL_TRATAMENTOS: '/tratamento',
    URL_TRATAMENTO_CONTROLADORES: '/tratamento/controladores',
    URL_TRATAMENTO_VINCULOS: '/tratamento/' + param + '/vinculo-tratamento',
    URL_VINCULOS_TRATAMENTO: '/tratamento/vinculo-tratamento',
    URL_VINCULOS_TRATAMENTO_SUMARIO: '/tratamento/vinculo-tratamento/summarized',

    getTitle(page: Page): string {
        return pages[page].title
    },

    getRoute(page: Page): string {
        return pages[page].route
    },

    getPageOrigin(page: Page): Page {
        const origin = pages[page].origin
        return (origin) ? origin : Page.HOME
    },

    getApiUrl(url: Optional<string>): string {
        let domain: Optional<string> = process.env.REACT_APP_BASE_URL
        if (domain) {
            if (domain.endsWith('/')) {
                domain = stringUtils.removeLastChar(domain);
            }
            return domain + url ?? '';
        }

        let origin = window.location.origin;
        if (origin.endsWith('/')) {
            origin = stringUtils.removeLastChar(origin);
        }
        return origin + DOMAIN_API + url ?? '';
    },
}

export default AppRoutes;