import { AxiosError, AxiosRequestConfig } from "axios";
import axios from 'axios';
import AppRoutes from "./app-routes";
import { DecodedURL, HttpResponse } from "./types";
import tokenUtils from "../utils/tokenUtils";

const HTTP_GET = 'GET'
const HTTP_POST = 'POST'
const HTTP_PUT = 'PUT'

const HTTP_200 = 200
const HTTP_401 = 401
const HTTP_403 = 403
const HTTP_404 = 404
const HTTP_422 = 422
const HTTP_500 = 500
const HTTP_REQUEST_ERROR = 0
const HTTP_UNKNOWN_ERROR = -1

const api = {

    HTTP_200,
    HTTP_401,
    HTTP_403,
    HTTP_404,
    HTTP_422,
    HTTP_500,
    HTTP_REQUEST_ERROR,
    HTTP_UNKNOWN_ERROR,

    //----------------- Autenticação -----------------

    async authEndpoint(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_AUTH);
        return resp
    },

    async login(code: string): Promise<HttpResponse> {
        let resp = await this.postRequestJson(AppRoutes.URL_LOGIN, { code: code })
        return resp
    },

    async logout(params: Map<string, string>): Promise<HttpResponse> {
        let url = this.urlWithQueryParams(AppRoutes.URL_LOGOUT, params)
        let resp = await this.getRequest(url)
        return resp
    },

    async updateTitular(params: Object) {
        let resp = await this.postRequestJson(AppRoutes.URL_UPDATE_TITULAR, params)
        return resp
    },

    //----------------- Controladores --------------

    async getControlador(id: string): Promise<HttpResponse> {
        let url = this.urlWithParams(AppRoutes.URL_CONTROLADOR, [id])
        let resp = await this.getRequest(url)
        return resp
    },

    async getControladores(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_CONTROLADORES)
        return resp
    },

    async getImagemControlador(idImg: string): Promise<HttpResponse> {
        let url = this.urlWithParams(AppRoutes.URL_CONTROLADOR_IMAGEM, [idImg])
        let resp = await this.getRequest(url)
        return resp
    },

    //----------------- Consentimentos --------------

    async getConsentimentos(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_CONSENTIMENTOS)
        return resp
    },

    async getConsentimento(id: string): Promise<HttpResponse> {
        // @TODO
        return { success: false }
    },

    async getSumarioConsentimentos(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_SUMARIO_CONSENTIMENTOS)
        return resp
    },

    async updateConsentimento(params: Object): Promise<HttpResponse> {
        let resp = await this.putRequestJson(AppRoutes.URL_CONSENTIMENTO_UPDATE, params)
        return resp
    },

    //----------------- Reclamações --------------

    async getReclamacoes(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_RECLAMACOES)
        return resp
    },

    async getReclamacoesControladores(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_RECLAMACOES_CONTROLADORES)
        return resp
    },

    async getSumarioReclamacoes(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_SUMARIO_RECLAMACOES)
        return resp
    },

    async novaReclamacao(params: Object): Promise<HttpResponse> {
        let resp = await this.postRequestJson(AppRoutes.URL_RECLAMACOES, params)
        return resp
    },

    async devolverReclamacao(params: Object) {
        let resp = await this.putRequestJson(AppRoutes.URL_DEVOLVER_RECLAMACAO, params)
        return resp
    },

    //----------------- Requisições --------------

    async getRequisicoes(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_REQUISICOES)
        return resp
    },

    async getRequisicao(id: string): Promise<HttpResponse> {
        let url = this.urlWithParams(AppRoutes.URL_REQUISICOES, [id])
        let resp = await this.getRequest(url)
        return resp
    },

    async getSumarioRequisicoes(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_SUMARIO_REQUISICOES)
        return resp
    },

    async getReqControladores(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_REQUISICOES_CONTROLADORES)
        return resp
    },

    async novaRequisicao(params: Object) {
        let resp = await this.postRequestJson(AppRoutes.URL_REQUISICOES, params)
        return resp
    },

    async getTiposRequisicao(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_TIPOS_REQUISICAO)
        return resp
    },

    async downloadPdfRequisicao(id: string) {
        let url = this.urlWithParams(AppRoutes.URL_REQUISICAO_DOWNLOAD_PDF, [id])
        let resp = await this.getRequest(url, true)
        return resp
    },

    async downloadAnexoHistReq(idReq: string, idHist: string) {
        let url = this.downloadAnexoHistReqLink(idReq, idHist)
        let resp = await this.getRequest(url)
        return resp
    },

    downloadAnexoHistReqLink(idReq: string, idHist: string) {
        let url = this.urlWithParamsReplace(AppRoutes.URL_REQUISICAO_DOWNLOAD_ANEXO_HIST, [idReq, idHist])
        return url
    },

    async avaliarRequisicao(params: Object) {
        let resp = await this.putRequestJson(AppRoutes.URL_AVALIAR_REQUISICAO, params)
        return resp
    },

    async devolverRequisicao(params: Object) {
        let resp = await this.putRequestJson(AppRoutes.URL_DEVOLVER_REQUISICAO, params)
        return resp
    },

    async cancelarRequisicao(params: Object) {
        let resp = await this.putRequestJson(AppRoutes.URL_CANCELAR_REQUISICAO, params)
        return resp
    },

    async getMotivosCancelamento() {
        let resp = await this.getRequest(AppRoutes.URL_MOTIVOS_CANCELAMENTO)
        return resp
    },

    //----------------- Tratamentos --------------

    async getTratamentos(params: Map<string, string>): Promise<HttpResponse> {
        let url = this.urlWithQueryParams(AppRoutes.URL_TRATAMENTOS, params)
        let resp = await this.getRequest(url)
        return resp
    },

    async getTratamentosVinculoCtrl(idControlador: string): Promise<HttpResponse> {
        let url = this.urlWithParamsReplace(AppRoutes.URL_TRATAMENTO_VINCULOS, [idControlador])
        let resp = await this.getRequest(url)
        return resp
    },

    async getControladoresTratamento(): Promise<HttpResponse> {
        let resp = await this.getRequest(AppRoutes.URL_TRATAMENTO_CONTROLADORES)
        return resp
    },

    async getVinculoTratamento(idVinculo: string): Promise<HttpResponse> {
        let url = this.urlWithParams(AppRoutes.URL_TRATAMENTOS, [idVinculo])
        let resp = await this.getRequest(url)
        return resp
    },

    //================= HELPERS ==================

    urlWithParams(route: string, params: string[]): string {
        let reqUrl = route;
        if (params && params.length) {
            if (this.routeParams(reqUrl)) {
                reqUrl = this.replaceParams(reqUrl, params);
            } else {
                params.forEach(param => {
                    reqUrl += "/" + param;
                });
            }
        }
        if (reqUrl.endsWith("/")) {
            reqUrl = reqUrl.slice(0, -1);
        }
        return reqUrl;
    },

    //---------------------------------------------------

    routeParams(url: string): boolean {
        const paramIndex = url.indexOf(AppRoutes.param);
        return paramIndex > 0;
    },

    //---------------------------------------------------

    replaceParams(url: string, params: string[]): string {
        return url.replace(/{param}/g, () => {
            return params.shift() || '';
        });
    },

    //---------------------------------------------------

    urlWithParamsReplace(route: string, params: string[]): string {
        let reqUrl = route;
        if (params && params.length) {
            params.forEach(param => {
                reqUrl = reqUrl.replace(AppRoutes.param, param);
            });
        }
        if (reqUrl.endsWith("/")) {
            reqUrl = reqUrl.slice(0, -1);
        }
        return reqUrl;
    },

    //---------------------------------------------------

    urlWithQueryParams(route: string, params: Map<string, string | number>) {
        let reqUrl = route + "?";
        params.forEach((value: string | number, key: string, map: Map<string, string | number>) => {
            reqUrl += `${key}=${value}&`
        });
        while (reqUrl.endsWith("/") || reqUrl.endsWith("&")) {
            reqUrl = reqUrl.slice(0, -1);
        }

        return reqUrl;
    },

    //---------------------------------------------------

    decodeUrl(fullUrl: string): DecodedURL {
        let splitUrl = fullUrl.split('?');
        let params: { [key: string]: string } = {}

        if (splitUrl.length === 2) {
            let query = splitUrl[1].split('&')
            query.forEach(function (param) {
                let splitParam = param.split('=')
                params[splitParam[0]] = splitParam[1]
            })
        }
        return { url: splitUrl[0], params: params }
    },

    //---------------------------------------------------

    async getRequest(uri: string, responseBuffer?: boolean): Promise<HttpResponse> {
        const config = await this.requestConfig(HTTP_GET, responseBuffer);
        try {
            let url = AppRoutes.getApiUrl(uri);
            let response = await axios.get(url, config)
            return { data: response.data ?? undefined, success: this.HTTPSuccess(response.status), status: response.status };
        } catch (error) {
            return this.getAxiosErrorResponse(error as AxiosError)
        }
    },

    //---------------------------------------------------

    async postRequestJson(url: string, data: Object) {
        return await this.postRequest(url, JSON.stringify(data));
    },

    //---------------------------------------------------

    async postRequest(uri: string, data: string): Promise<HttpResponse> {
        const config = await this.requestConfig(HTTP_POST);
        try {
            let url = AppRoutes.getApiUrl(uri);
            let response = await axios.post(url, data, config)
            return { data: response.data ?? undefined, success: this.HTTPSuccess(response.status), status: response.status };
        } catch (error) {
            return this.getAxiosErrorResponse(error as AxiosError)
        }
    },

    //---------------------------------------------------

    async putRequestJson(url: string, data: Object) {
        return await this.putRequest(url, JSON.stringify(data));
    },

    //---------------------------------------------------

    async putRequest(uri: string, data: string): Promise<HttpResponse> {
        const config = await this.requestConfig(HTTP_PUT);
        try {
            let url = AppRoutes.getApiUrl(uri);
            let response = await axios.put(url, data, config)
            return { data: response.data ?? undefined, success: this.HTTPSuccess(response.status), status: response.status };
        } catch (error) {
            return this.getAxiosErrorResponse(error as AxiosError)
        }
    },

    //---------------------------------------------------

    getAxiosErrorResponse(error: AxiosError): HttpResponse {
        let status: number

        if (error.response) {
            status = error.response.status
        } else if (error.request) {
            status = error.status ?? HTTP_REQUEST_ERROR
        } else {
            status = error.status ?? HTTP_UNKNOWN_ERROR
        }

        if (status === HTTP_401 || status === HTTP_REQUEST_ERROR) {
            this.reload()
        }

        return { success: false, status: status, message: error.code };
    },

    //---------------------------------------------------

    reload() {
        let origin = window.location.origin
        window.location.replace(origin)
    },

    //---------------------------------------------------

    requestConfig(method: string, responseBuffer?: boolean): AxiosRequestConfig {
        const token = tokenUtils.get();

        let config: AxiosRequestConfig = {
            headers: token ? { Authorization: token } : {}
        }

        if (responseBuffer) {
            config = {...config, responseType: 'arraybuffer'}
        }

        switch (method) {
            case HTTP_GET:
                return {
                    ...config,
                    headers: { ...config.headers, "accept": "*/*" }
                }
            case HTTP_POST:
            case HTTP_PUT:
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        "Content-Type": "application/json"
                    }
                }
            default:
                return {}
        }
    },

    //---------------------------------------------------

    getValidCookie() {
        // @TODO
        return ''
    },

    //---------------------------------------------------

    HTTPSuccess(status: number) {
        return status >= 200 && status < 400
    },
}

export default api;