import '../style/footer.css';
import Assets from '../assets';
import lang from '../lang';
import '../style/header.css';
import AppRoutes from '../api/app-routes';

export const Header = () => {

    // ----- styles -----

    const style = {
        justifyContent: 'space-between',
        alignContent: 'center',
        display: 'flex',
    }

    const librasStyle = {
        height: 20,
    }

    const logoStyle = {
        height: 80,
        margin: 20,
    }

    // ----- render -----

    return (
        <header id="Header" className={'App-header'}>
            <div className="container-lg" style={style}>
                <div className="header-top">
                    <img style={logoStyle} src={Assets.SERPRO_LGPD_WHITE} alt={lang.tr('lgpd_logo')} />
                </div>
                <div>
                    <a href={AppRoutes.URL_VLIBRAS} target='_blank' rel="noreferrer">
                        <img style={librasStyle} src={Assets.VLIBRAS_WHITE} alt={lang.tr('vlibras')} />
                        <label style={{ color: 'white' }}>{lang.tr('vlibras')}</label>
                    </a>
                </div>
            </div>
        </header >

    )
}

export default Header
