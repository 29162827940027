import { jwtDecode } from 'jwt-decode';
import storage from '../context/storage';

const defaultTokenExpirationTime = 1200;

const tokenUtils = {
    get(): string {
        let token = storage.getCookie(storage.TOKEN);
        return token ?? '';
    },

    store(token: string) {
        const decodedToken = jwtDecode(token);
        let expDate = new Date();

        if (decodedToken.exp) {
            expDate = new Date(decodedToken.exp * 1000);
        } else {
            expDate.setSeconds(
                expDate.getSeconds() + defaultTokenExpirationTime
            );
        }

        storage.setCookie(token, {
            secure: true,
            expires: expDate
        });
    },

    clear() {
        storage.clearCookie();
    }
};

export default tokenUtils;
