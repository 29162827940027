import { BrButton, BrMessage } from '@govbr-ds/react-components';
import { Page } from '../../types/page';
import PageComponent from '../../components/PageComponent';
import { useEffect, useState } from 'react';
import requisicoesService from '../../service/requisicoesService';
import lang from '../../lang';
import { usePageContext } from '../../context/pageContext';
import '../../style/style.css';
import { Col, BrLoading } from '@govbr-ds/react-components';
import { Requisicao } from '../../types/dtos';
import { StatusRequisicao } from '../../types/enums';
import RequisicoesMenu from './RequisicoesMenu';
import RequisicaoCard from './RequisicaoCard';
import ModalCancelamento from './ModalCancelamento';
import ModalTratamentoReq from './ModalTratamentoReq';

const RequisicoesPage = () => {
    const { openPage } = usePageContext();

    const [requisicoes, setRequisicoes] = useState<Optional<Requisicao[]>>([]);
    const [requisicao, setRequisicao] = useState(
        undefined as Optional<Requisicao>
    );
    const [showCancel, setShowCancel] = useState<boolean>();
    const [showTratamento, setShowTratamento] = useState<boolean>();

    const [loading, setLoading] = useState(false);
    //const [showConfirm, setShowConfirm] = useState<Optional<ModalConfirmStatus>>();

    useEffect(() => {
        fetch()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [requisicoes, requisicao, showCancel])

    const fetch = async () => {
        setLoading(true)
        requisicoesService.getRequisicoes().then((resp) => {
            setRequisicoes(resp)
            if (requisicao) {
                let filter = resp.filter(r => r.id === requisicao.id)
                setRequisicao(filter.length > 0 ? filter[0] : undefined)
            }
            setLoading(false)
        })
    }

    const newRequest = () => {
        openPage(Page.NOVA_REQUISICAO)
    }

    const onUpdate = (status: StatusRequisicao) => {
        if (requisicao) {
            switch (status) {
                case StatusRequisicao.CANCELADO:
                    setShowCancel(true);
                    break;
                case StatusRequisicao.INFORMACAO_ENVIADA:
                    setShowTratamento(true)
                    break;
                default:
                    break;
            }
        }
    }

    const onModalDismissed = (refresh: boolean) => {
        setShowCancel(false)
        setShowTratamento(false)
        if (refresh) {
            fetch()
        }
    }

    const ShowModal = () => {
        if (requisicao) {
            if (showCancel) {
                return (
                    <ModalCancelamento
                        requisicao={requisicao}
                        onDismiss={(refresh) => onModalDismissed(refresh)} />
                )
            } else if (showTratamento) {
                return (
                    <ModalTratamentoReq
                        requisicao={requisicao}
                        onDismiss={(refresh) => onModalDismissed(refresh)} />
                )
            }
        }
        return null
    }

    // ---------- Render ----------

    const RequisicoesPanel = () => {
        if ((requisicoes ?? []).length > 0) {
            return <div className="listcard-container">
                <Col>
                    <RequisicoesMenu
                        requisicoes={requisicoes ?? []}
                        selected={requisicao}
                        setRequisicao={setRequisicao}
                    />
                </Col>

                <Col>
                    {requisicao && (
                        <RequisicaoCard requisicao={requisicao} onUpdate={onUpdate} />
                    )}
                </Col>

                {ShowModal()}

            </div>
        }
        return <>
            <BrMessage type={'info'} message={lang.tr('requests_not_found')}></BrMessage>
            <br />
        </>
    }

    // --------------------

    return (
        <PageComponent page={Page.REQUISICOES}>
            {loading
                ? <BrLoading />
                : <>
                    {RequisicoesPanel()}
                    <center>
                        <BrButton primary onClick={newRequest}>{lang.tr('nova_requisicao')}</BrButton>
                    </center>
                </>
            }
        </PageComponent>
    );
};

export default RequisicoesPage;
