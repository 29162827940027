import { Routes, Route } from 'react-router-dom';
import './App.css';

import AppRoutes from './api/app-routes'
import { Page } from './types/page';
import HomePage from './pages/HomePage';
import ConsentimentosPage from './pages/ConsentimentosPage';
import InfoTratamentoPage from './pages/InfoTratamentoPage';
import TratamentoDetalhePage from './pages/InfoTratamentoPage/TratamentoDetalhePage';
import ReclamacoesPage from './pages/ReclamacoesPage';
import NovaReclamacaoPage from './pages/ReclamacoesPage/NovaReclamacaoPage';
import NovaReclamacaoFormPage from './pages/ReclamacoesPage/NovaReclamacaoFormPage';
import RequisicoesPage from './pages/RequisicoesPage';
import NovaRequisicaoPage from './pages/RequisicoesPage/NovaRequisicaoPage';
import PoliticasPrivacidadePage from './pages/PoliticasPrivacidadePage';
import SplashPage from './pages/SplashPage';
import PageProvider from './context/pageContext';

function App() {
  return (
    <PageProvider>
      <Routes>
        <Route path={AppRoutes.getRoute(Page.SPLASH)} element={<SplashPage />} />
        <Route path={AppRoutes.getRoute(Page.LOGIN)} element={<SplashPage />} />
        <Route path={AppRoutes.getRoute(Page.LOGOUT)} element={<SplashPage />} />
        <Route path={AppRoutes.getRoute(Page.HOME)} element={<HomePage />} />
        <Route path={AppRoutes.getRoute(Page.CONSENTIMENTO)} element={<ConsentimentosPage />} />
        <Route path={AppRoutes.getRoute(Page.INFO_TRATAMENTO)} element={<InfoTratamentoPage />} />
        <Route path={AppRoutes.getRoute(Page.INFO_TRATAMENTO_DETALHADO)} element={<TratamentoDetalhePage />} />
        <Route path={AppRoutes.getRoute(Page.RECLAMACOES)} element={<ReclamacoesPage />} />
        <Route path={AppRoutes.getRoute(Page.NOVA_RECLAMACAO)} element={<NovaReclamacaoPage />} />
        <Route path={AppRoutes.getRoute(Page.NOVA_RECLAMACAO_FORM)} element={<NovaReclamacaoFormPage />} />
        <Route path={AppRoutes.getRoute(Page.REQUISICOES)} element={<RequisicoesPage />} />
        <Route path={AppRoutes.getRoute(Page.NOVA_REQUISICAO)} element={<NovaRequisicaoPage />} />
        <Route path={AppRoutes.getRoute(Page.POLITICAS)} element={<PoliticasPrivacidadePage />} />
      </Routes>
    </PageProvider>
  );
}

export default App;
