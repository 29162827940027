import { BrTag, Typography } from '@govbr-ds/react-components';
import { getTagColor } from '.';
import { Consentimento } from '../../service/consentimentosService';
import lang from '../../lang';

const ConsentItem = (props: {
    consentimento: Consentimento;
    selectedConsentimento: Optional<Consentimento>;
    key: string;
    setConsentimento: Function;
}) => {
    const className = 'menu-item divider clickable';
    const selectedClassName = className + ' selected';

    const isSelected = props.selectedConsentimento === props.consentimento;

    return (
        <span
            className={isSelected ? selectedClassName : className}
            style={{ justifyContent: 'space-between' }}
            onClick={() => {
                props.setConsentimento(props.consentimento);
            }}
        >
            <div>
                <Typography weight="semi-bold" size="up-01">
                    {props.consentimento.controlador.nome}
                </Typography>
            </div>

            <div>
                <BrTag
                    color={getTagColor(props.consentimento.status)}
                    value={lang.tr(props.consentimento.status)}
                    type="text"
                />
            </div>
        </span>
    );
};

export default ConsentItem;
