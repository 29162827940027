import { Page } from '../../types/page';
import { BrCard, Row, Typography, Icon } from '@govbr-ds/react-components';
import { VinculoTratamento, HistoricoTratamento, Tratamento, Hipoteses } from '../../types/dtos';
import PageComponent from '../../components/PageComponent';
import { useEffect, useState } from "react";
import tratamentoService from '../../service/tratamentoService'
import { ReactNode } from 'react';
import lang from '../../lang';
import ListHistorico from '../../components/TratamentoInfo';
import { usePageContext } from '../../context/pageContext';
import '../../style/info.css'
import stringUtils from '../../utils/stringUtils';
import { ManeirasObtencaoInformacao } from '../../types/enums';
import TratamentoLayout from '../../types/tratamento';
import { FontWeight } from '@govbr-ds/react-components/dist/types/text';

interface LabelInfoProps {
    label: string;
    value?: string;
    icon?: string;
    children?: ReactNode;
    weight?: FontWeight;
}

interface DetailProps {
    tratamento?: Optional<Tratamento>
    hipoteses?: Optional<Hipoteses[]>
}

const LabelInfo = ({
    label,
    value,
    icon,
    children,
    weight,
}: LabelInfoProps) => {
    return (
        <div className={'info-panel'}>
            <Typography htmlElement="p" weight={weight ?? "bold"} className="mb-1n">
                {label}
            </Typography>
            {icon && <Icon icon={icon} />}
            {value ? <span className='info-text'>{value}</span> : children}
        </div>
    );
};

const RenderDescription = ({ tratamento }: DetailProps) => {
    return (
        <>
            <Row className="card-header" justifyContent="between">
                <LabelInfo label={lang.tr('agente_tratamento')}
                    value={tratamento?.controlador.nome} />
            </Row>

            <Row className="card-header" justifyContent="between">
                <LabelInfo label={lang.tr('finalidade')}
                    value={tratamento?.finalidade.descricao} />
            </Row>

            {!stringUtils.isNullOrEmpty(tratamento?.descricao) &&
                <Row className="card-header" justifyContent="between">
                    <LabelInfo label={lang.tr('descricao')}
                        value={tratamento?.descricao} />
                </Row>
            }

            {tratamento?.eventosTratamento &&
            <Row className="card-header" justifyContent="between">
                <LabelInfo label={lang.tr('eventos')}>
                    <ul>
                        {tratamento.eventosTratamento.map((evt) => {
                            return (
                                <li key={Math.random().toString()}>{evt}</li>
                            )
                        })}
                    </ul>
                </LabelInfo>
            </Row>
            }
        </>
    );
};

const RenderHipoteseTratamento = ({ hipoteses }: DetailProps) => {

    if (hipoteses === undefined) {
        return null
    }

    let hipotesePessoal: Hipoteses[] = hipoteses.filter(h => !h.hipoteseTratamento.envolveDadoSensivel)
    let hipoteseSensivel: Hipoteses[] = hipoteses.filter(h => h.hipoteseTratamento.envolveDadoSensivel === true)

    return (
        <Row className="card-header" justifyContent="between">
            {hipotesePessoal.length > 0 &&
                <LabelInfo label={lang.tr('h_tratamento_pessoais')}>
                    <ul>
                        {hipoteses.map(hipotese => {
                            return (
                                <div key={hipotese.hipoteseTratamento.id} className={'info-panel'}>
                                    <li>{hipotese.hipoteseTratamento.nome}</li>
                                    {!stringUtils.isNullOrEmpty(hipotese.complemento) &&
                                        <LabelInfo label={lang.tr("descricao")} weight={'medium'}>{hipotese.complemento}</LabelInfo>
                                    }
                                </div>
                            )
                        })}
                    </ul>
                </LabelInfo>
            }
            {hipoteseSensivel.length > 0 &&
                <LabelInfo label={lang.tr('h_tratamento_pessoais_sensivel')}>
                    <ul>
                        {hipoteseSensivel.map(hipotese => {
                            return (
                                <div key={hipotese.hipoteseTratamento.id} className={'info-panel'}>
                                    <li>{hipotese.hipoteseTratamento.nome}</li>
                                    {!stringUtils.isNullOrEmpty(hipotese.complemento) && 
                                    <LabelInfo label={lang.tr("descricao")}>{hipotese.complemento}</LabelInfo>}
                                </div>
                            )
                        })}
                    </ul>
                </LabelInfo>
            }
        </Row>
    );
};

const TratamentoDetalhePage = () => {
    const { pageParams } = usePageContext();

    const [objLayout] = useState<Optional<TratamentoLayout>>(pageParams() as Optional<TratamentoLayout>)
    const [vinculo, setVinculo] = useState<Optional<VinculoTratamento>>()

    useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
    }, [vinculo])

    const fetchData = async () => {
        if (objLayout) {
            await tratamentoService.getVinculoTratamento(objLayout.id).then((response) => {
                setVinculo(response)
            })
        }
    }

    const maneirasObtencaoInfo = () => {
        return (
            <ul>
            {vinculo?.tratamento.maneirasObtencaoInformacao.map((info) => {
                return (
                    info === ManeirasObtencaoInformacao.PRESENCIALMENTE
                        ? <li key={info}>{lang.tr('comparecer_presencialmente')}</li>
                    : info === ManeirasObtencaoInformacao.REQUISICAO_ELETRONICA
                        ? <li key={info}>{lang.tr('requisicao_eletronica')}</li>
                    : info === ManeirasObtencaoInformacao.OUTRO
                        ? <li key={info}>{vinculo?.tratamento.detalheOutraManeiraObtencaoInf}</li>
                    : <li key={info}>{lang.tr('outro')}</li>
                )
            })}
            </ul>
        )
    }

    return (
        <PageComponent page={Page.INFO_TRATAMENTO_DETALHADO} reset={objLayout === undefined}>
            <BrCard style={{ padding: '1rem' }}>

                {vinculo?.tratamento &&
                    <>
                    <RenderDescription tratamento={vinculo?.tratamento} />
                    {vinculo?.tratamento.hipoteses &&
                        <RenderHipoteseTratamento hipoteses={vinculo.tratamento.hipoteses} />
                    }
                    <br />
                    </>
                }
                
                <Typography htmlElement='p' weight="bold" className="mb-1n">{lang.tr('como_exercer_direitos')}</Typography>
                {maneirasObtencaoInfo()}

                <Typography htmlElement='p' weight="bold" className="mb-1n">{lang.tr('data_ocorrencia')}</Typography>

                {vinculo?.historico && (vinculo?.historico.map((historico: HistoricoTratamento) => (
                    <div className={'info-panel'} key={historico.id}>
                        <ListHistorico historicoTratamento={historico}></ListHistorico>
                    </div>
                )))}
                <Typography htmlElement='span' weight="regular" className="mb-1n warning-before">{''}</Typography>
                <Typography htmlElement='span' weight="regular" className="mb-1n footnote">{lang.tr('info_dados_sensiveis')}</Typography>
            </BrCard>
        </PageComponent >
    )
}
export default TratamentoDetalhePage;
