import { useDropzone } from 'react-dropzone'
import lang from '../lang';
import { useCallback, useEffect, useState } from 'react';
import Assets from '../assets';
import '../style/style.css'
import { FileUploadData } from '../types/fileUpload';

interface FileUploadProps {
    tag: string;
    onLoad: (tag: string, file: FileUploadData) => void;
    onRemove: (tag: string) => void;
}

const FileUpload = ({ tag, onLoad, onRemove }: FileUploadProps) => {
    const onDrop = useCallback((acceptedFiles: any) => {
        fileLoaded(acceptedFiles[0])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [file, setFile] = useState<File>();

    const { getRootProps, getInputProps } = useDropzone({ onDrop,
        maxFiles: 1,
        accept: {
          "image/jpg": [".jpg"],
          "image/jpeg": [".jpeg"],
          "image/png": [".png"],
          "image/bmp": [".bmp"],
        }, })
    const reader = new FileReader();

    useEffect(() => {

    }, [file])

    const fileLoaded = (loaded: File) => {
        reader.onload = () => {
            let binaryStr = reader.result as string

            if (binaryStr.startsWith(`data:${loaded.type};base64,`)) {
                binaryStr = binaryStr.split(",")[1]
            }

            setFile(loaded)

            let uploadData: FileUploadData = {
                tag: tag,
                base64: binaryStr,
                name: loaded.name,
                type: loaded.type,
            }

            onLoad(tag, uploadData)
        }

        reader.readAsDataURL(loaded)
    }

    const remove = () => {
        setFile(undefined)
        onRemove(tag)
    }

    // ----- Styles -----

    const boxStyle: React.CSSProperties = {
        backgroundColor: '#ececec',
        borderWidth: 1,
        borderRadius: 5,
        padding: '10px',
        marginBottom: '10px',
        display: 'flex',
    }

    const column: React.CSSProperties = {
        float: 'left',
        padding: '5px',
    }

    const iconStyle: React.CSSProperties = {
        height: 20
    }

    // ----- Render -----

    return (
        <div style={{ display: 'inline-block' }}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    <>
                        <span style={{ fontSize: '12px' }}>{lang.tr('image')} {tag}</span>
                        <br />
                        <div style={column}>
                            <span style={boxStyle}>
                                <div style={column}>
                                    <img style={iconStyle} src={Assets.CLOUD_UPLOAD} alt={lang.tr('upload_anexo')} />
                                </div>
                                {file
                                    ? <>
                                        <div style={column}>
                                            {file.name}
                                        </div>
                                        <div style={column}>
                                            <span onClick={remove}>
                                                <img style={iconStyle} src={Assets.TRASH} alt={lang.tr('excluir')} />
                                            </span>
                                        </div>
                                    </>
                                    :
                                    <div style={column}>
                                        <span dangerouslySetInnerHTML={{ __html: `<p style="font-size: 14px;">${lang.tr('arraste_arquivo_imagem')}</p>` }} />
                                    </div>
                                }
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default FileUpload;