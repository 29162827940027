import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import api from "../api";
import { HttpResponse } from "../api/types";
import { AuthInfo } from "../types/authInfo";
import storage from "./storage";
import { SessionInfo } from "../types/dtos";
import tokenUtils from "../utils/tokenUtils";
import stringUtils from "../utils/stringUtils";
import popup, { PopupMessage } from '../common/popup';
import lang from "../lang";
import AppRoutes from "../api/app-routes";

export type AuthContextType = {
    authInfo: AuthInfo;
    govBREndpoint: () => void;
    login: (code: string) => void;
    logout: () => void
};

export const AuthContext = createContext<Nullable<AuthContextType>>(null);
export const useAuthContext = () => useContext(AuthContext) as AuthContextType;

const defaultAuth: AuthInfo = {
    authenticated: false,
    userInfo: undefined,
    token: '',
    error: false,
}

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [authInfo, setAuthInfo] = useState<AuthInfo>(defaultAuth);

    async function govBREndpoint() {
        api.authEndpoint().then((resp: HttpResponse) => {
            if (resp.success) {
                window.open(resp.data as string, "_self")
            } else {
                let popupMsg: PopupMessage = popup.errorConfig(lang.tr('erro_login'))
                popup.show(popupMsg)
            }
        })
    }

    function login(code: string) {
        api.login(code).then((resp: HttpResponse) => {
            if (resp.success) {
                let token = (resp.data ? resp.data as string : '')
                tokenUtils.store(token);
                setAuthInfo({ ...authInfo, authenticated: true, token: token })
                updateTitular()
            } else {
                let msg: string = resp.status === api.HTTP_422 ? 'erro_nivel_conta' : 'erro_login'
                let popupMsg: PopupMessage = popup.errorConfig(lang.tr(msg))
                popup.show(popupMsg)
            }
        })
    }

    function updateTitular() {
        try {
            let token: string = tokenUtils.get()
            if (!stringUtils.isNullOrEmpty(token)) {
                let tokenData: SessionInfo = jwtDecode(token);
                storage.set(storage.SESSION_INFO, JSON.stringify(tokenData))
            }
        } catch (error) {
            // Erro ao atualizar titular
        }
    }

    function logout() {
        let origin = AppRoutes.getApiUrl(AppRoutes.URL_RETORNO_LOGOUT);
        let params: Map<string, string> = new Map<string, string>();

        params.set(AppRoutes.PARAM_LOGOUT_URI_RETORNO, origin)
        api.logout(params).then((resp) => {
            if (resp.success) {
                let popupMsg: PopupMessage = popup.successConfig(lang.tr('logout_ok'))
                popup.show(popupMsg)
            }
            cleanupSession()
        });
    }

    function cleanupSession() {
        tokenUtils.clear();
        storage.clear();
        setAuthInfo(defaultAuth);
    }

    return (
        <AuthContext.Provider value={{ authInfo, govBREndpoint, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
