import { ChangeEvent, useEffect, useState } from 'react';
import { BrButton, BrCard, BrMessage, BrTextarea, Row, Typography } from '@govbr-ds/react-components';
import { Page } from '../../types/page';
import PageComponent from '../../components/PageComponent';
import lang from '../../lang';
import { Controlador } from '../../types/dtos';
import { usePageContext } from '../../context/pageContext';
import reclamacoesService from '../../service/reclamacoesService';
import popup, { PopupMessage } from '../../common/popup';
import FileUpload from '../../components/FileUpload';
import { FileUploadData } from '../../types/fileUpload';
import stringUtils from '../../utils/stringUtils';
import MandatoryField from '../../components/MandatoryField';

const NovaReclamacaoFormPage = () => {
    const { openPage, setShowPopup, pageParams } = usePageContext();

    const [controller] = useState<Optional<Controlador>>(pageParams() as Optional<Controlador>)
    const [detalhes, setDetalhes] = useState<Optional<string>>();
    const [file1, setFile1] = useState<Nullable<FileUploadData>>();
    const [file2, setFile2] = useState<Nullable<FileUploadData>>();
    const [file3, setFile3] = useState<Nullable<FileUploadData>>();
    const [validDetail, setValidDetail] = useState<boolean>();

    useEffect(() => {
    }, [file1, file2, file3, validDetail])

    const onDetailsChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let text = event.target.value;
        setDetalhes(text);
        setValidDetail(!stringUtils.isNullOrEmpty(text))
    }

    const sendComplaint = () => {
        if (stringUtils.isNullOrEmpty(detalhes)) {
            setValidDetail(false)
            return
        }

        if (controller && detalhes) {
            let files: FileUploadData[] = [];
            if (file1) {
                files.push(file1);
            }
            if (file2) {
                files.push(file2);
            }
            if (file3) {
                files.push(file3);
            }
            reclamacoesService.novaReclamacao(controller.id, detalhes, files).then((resp) => {
                let popupMsg: PopupMessage = resp.success
                    ? popup.successConfig(lang.tr('cria_reclamacao_ok'))
                    : popup.errorConfig(lang.tr('cria_reclamacao_erro'))
                setShowPopup(popupMsg)
                if (resp.success) {
                    openPage(Page.RECLAMACOES, { replace: true })
                }
            })
        }
    }

    const renderMessage = () => {
        return (
            <Typography htmlElement='p' size="up-01">
                <span dangerouslySetInnerHTML={{ __html: `<p>${lang.tr('aviso_reclamacao_lgpd')}</p>` }} />
            </Typography>
        )
    }

    return (
        <PageComponent page={Page.NOVA_RECLAMACAO_FORM} reset={controller === undefined}>
            <BrCard>
                <BrMessage type={'info'} message={renderMessage()}></BrMessage>

                <Typography htmlElement='p' size="up-01">
                    {lang.tr('controlador')}:
                    <br />
                    <b>{controller?.razaoSocial}</b>
                </Typography>

                <MandatoryField validate={validDetail === false}>
                    <BrTextarea id="textarea-id1"
                        label={lang.tr('descreva_detalhes_reclamacao')}
                        placeholder={lang.tr('detalhes')}
                        onChange={(evt) => onDetailsChanged(evt)}
                    ></BrTextarea>
                </MandatoryField>

                <div style={{ margin: '10px' }}>
                    <Row justifyContent='start'>
                        <FileUpload tag={'1'} onLoad={(tag, file) => setFile1(file)} onRemove={(tag) => setFile1(null)} />
                        <FileUpload tag={'2'} onLoad={(tag, file) => setFile2(file)} onRemove={(tag) => setFile2(null)} />
                        <FileUpload tag={'3'} onLoad={(tag, file) => setFile3(file)} onRemove={(tag) => setFile3(null)} />
                    </Row>
                </div>

                <center>
                    <BrButton primary onClick={sendComplaint}>{lang.tr('enviar')}</BrButton>
                </center>
            </BrCard>
        </PageComponent>
    );
};

export default NovaReclamacaoFormPage;
