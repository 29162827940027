import {
    BrButton,
    BrCard,
    BrLoading,
    Row,
    Typography
} from '@govbr-ds/react-components';
import lang from '../lang';
import { Page } from '../types/page';
import { Controlador } from '../types/dtos';
import { usePageContext } from '../context/pageContext';
import controladorService from '../service/controladorService';
import { useEffect, useState } from 'react';
import Assets from '../assets';

interface ComplaintAgentCardProps {
    controlador: Controlador;
}

const ComplaintAgentCard = ({ controlador }: ComplaintAgentCardProps) => {
    const { openPage } = usePageContext();
    const [image, setImage] = useState<string>(Assets.BLANK_SQUARE);

    useEffect(() => {
        fetchImage();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchImage()
    }, [controlador]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [image])

    const fetchImage = async () => {
        controladorService.getImagem(controlador.idImagem).then((resp) => {
            setImage(resp);
        });
    };

    const createComplaint = () => {
        openPage(Page.NOVA_RECLAMACAO_FORM, { state: controlador })
    };

    return controlador ? (
        <BrCard style={{ marginTop: '2rem' }}>
            <Row className="justify-center-flex">
                <div style={{ margin: '1rem 3rem 1rem 3rem' }}>
                    <img width="120px" src={image} alt={controlador.nome} />
                </div>
                <div style={{ margin: '1rem' }}>
                    <Typography size="up-01">
                        {`${lang.tr('encarregado')}: ${controlador.nome}`}
                    </Typography>
                    <Typography size="up-01">
                        {controlador.razaoSocial}
                    </Typography>
                    <Typography size="up-01">
                        {`${controlador.endereco.endereco}, ${controlador.endereco.complemento}`}
                    </Typography>
                    <Typography size="up-01">
                        {`${controlador.endereco.cidade}-${controlador.endereco.uf
                            }, ${lang.tr('cep')}: ${controlador.endereco.cep}`}
                    </Typography>
                </div>
            </Row>

            <center>
                <BrButton primary onClick={createComplaint}>{lang.tr('criar_nova_reclamacao_comunicado')}</BrButton>
            </center>

        </BrCard>
    ) : (
        <BrLoading />
    );
};

export default ComplaintAgentCard;
