import { BrItem, BrList, Row, Typography } from "@govbr-ds/react-components";
import '../../style/info.css';
import lang from '../../lang';
import TratamentoLayout from "../../types/tratamento";
import { usePageContext } from "../../context/pageContext";
import { Page } from "../../types/page";
import { Colors } from "../../types/colors";

interface TratamentoInfoProps {
    infoTratamento: TratamentoLayout[]
}
const InfoTratamentoList = ({ infoTratamento }: TratamentoInfoProps) => {
    const { openPage } = usePageContext();

    const openDetails = (tratamento: TratamentoLayout) => {
        openPage(Page.INFO_TRATAMENTO_DETALHADO, { state: tratamento })
    }

    return (
        <BrList title={lang.tr('finalidade')}>
            {infoTratamento.map((tratamento: TratamentoLayout, index: number) => (
                <BrItem divider density='media' key={index}>
                    <Row justifyContent='between'>
                        <Typography>{tratamento.finalidade}</Typography>
                        <Typography className={"link"}>
                            <span style={{ cursor: 'pointer', color: Colors.link }} onClick={() => openDetails(tratamento)}>{lang.tr('detalhar')}</span>
                        </Typography>
                    </Row>
                </BrItem>
            ))
            }
        </BrList>
    )
}

export default InfoTratamentoList;
