import { ReactElement, useEffect, useState } from 'react';
import lang from '../lang';
import { BrCard, BrItem, Typography } from '@govbr-ds/react-components';
import Pagination from './Pagination';

type Item = any;
type ItemBuilder = (arg0: Item) => ReactElement;
type SelectItem = (arg0: Item) => void;

interface PaginatedListProps {
    items: Item[];
    selectedItem: Optional<Item>;
    setSelectedItem: SelectItem;
    itemBuilder: ItemBuilder;
}

const PaginatedList = ({
    items,
    selectedItem,
    setSelectedItem,
    itemBuilder
}: PaginatedListProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const maxPagesShown = Math.min(totalPages, itemsPerPage);

    const mapItem = (item: object, index: number) => {
        const page = Math.floor(index / itemsPerPage) + 1;
        return <span key={Math.random().toString()}>{page === currentPage && itemBuilder(item)}</span>;
    };

    const getRegistrosText = (start: number, end: number, total: number) =>
        lang.tr('registros_contador', {
            start: start.toString(),
            end: end.toString(),
            total: total.toString()
        });

    useEffect(() => {
        if (!selectedItem && items.length > 0) {
            setSelectedItem(items[0]);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BrCard style={{ minHeight: '30%' }}>
            <div className="br-menu">
                <nav className="menu-body" role="tree">
                    {items.map((item, index) => mapItem(item, index))}
                    <BrItem>
                        <Typography
                            color="secondary-05"
                            weight="semi-bold"
                            size="up-01"
                        >
                            {getRegistrosText(
                                (currentPage - 1) * itemsPerPage + 1,
                                Math.min(
                                    currentPage * itemsPerPage,
                                    items.length
                                ),
                                items.length
                            )}
                        </Typography>
                    </BrItem>
                </nav>

                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    total={totalPages}
                    maxPagesShown={maxPagesShown}
                />
            </div>
        </BrCard>
    );
};

export default PaginatedList;
