import { MouseEventHandler } from 'react';
import lang from '../../lang';
import { BrButton, Icon } from '@govbr-ds/react-components';

interface MenuButtonProps {
    onClick: MouseEventHandler;
}

const MenuButton = ({ onClick }: MenuButtonProps) => {
    return (
        <BrButton onClick={onClick} className="br-button circle" circle={true}>
            <span className="sr-only">{lang.tr('menu')}</span>
            <Icon icon="fas fa-bars" />
        </BrButton>
    );
};

export default MenuButton;
