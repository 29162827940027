import RequisicoesPage from "./RequisicoesPage";
import { StatusRequisicao } from "../../types/enums";
import { BrColors } from "../../types/colors";

export const getTagColor = (status: string) => {
    switch (status) {
        case StatusRequisicao.CONCLUIDO:
            return BrColors.primary_lighten_02;
        case StatusRequisicao.PENDENTE:
            return BrColors.warning;
        case StatusRequisicao.EM_ATENDIMENTO:
            return BrColors.support_01;
        case StatusRequisicao.ATENDIDO:
            return BrColors.support_02;
        case StatusRequisicao.NAO_ATENDIDO:
            return BrColors.support_03;
        case StatusRequisicao.RESPONDIDO:
            return BrColors.highlight;
        case StatusRequisicao.INFORMACAO_SOLICITADA:
            return BrColors.support_07;
        case StatusRequisicao.INFORMACAO_ENVIADA:
            return BrColors.support_08;
        case StatusRequisicao.CANCELADO:
        default:
            return BrColors.secondary_07;
    }
};

export default RequisicoesPage;