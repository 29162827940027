import '../style/footer.css';
import Assets from '../assets';
import lang from '../lang';
import { Colors } from '../types/colors';
import AppRoutes from '../api/app-routes';

export const Footer = () => {

    // ----- styles -----

    const style = {
        backgroundColor: Colors.lightGray,
        borderWidth: 0,
    }

    const logoArea = {
        justifyContent: 'flex-end',
        display: 'flex',
    }

    const logoStyle = {
        height: 50,
        margin: 20,
    }

    // ----- render -----

    return (
        <footer role="contentinfo" className="br-footer inverted mb-4" style={style}>
            <div className="container-lg">
                <div className=".logo" style={logoArea}>
                    <a href={AppRoutes.URL_ACESSO_INFO} target='_blank' rel="noreferrer">
                        <img style={logoStyle} src={Assets.ACESSO_INFO} alt={lang.tr('acesso_informacao')} />
                    </a>
                    <a href={AppRoutes.URL_BRASIL_GOVBR} target='_blank' rel="noreferrer">
                        <img style={logoStyle} src={Assets.GOVERNO_FEDERAL} alt={lang.tr('governo_federal')} />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
