import { usePageContext } from '../../context/pageContext';
import { Page } from '../../types/page';
import Collapse from '../Collapse';

export interface MenuItemProps {
    name: string;
    page?: Page;
    children?: MenuItemProps[];
}

const MenuFolder = ({ name, children }: MenuItemProps) => {
    return (
        <Collapse name={name}>
            {children?.map((item, index) => (
                <li key={`collapse-item-${index}`}>
                    <MenuItem {...item} />
                </li>
            ))}
        </Collapse>
    );
};

export const MenuItem = (props: MenuItemProps) => {
    const { openPage } = usePageContext()

    const onClick = () => {
        props.page && openPage(props.page, { replace: true });
    };

    if (props.children) {
        return <MenuFolder {...props} />;
    }
    return (
        <span className="menu-item divider clickable" onClick={onClick}>
            <span className="content">{props.name}</span>
        </span>
    );
};

export default MenuItem;
