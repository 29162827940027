import { BrTag, Col, Typography } from '@govbr-ds/react-components';
import { getTagColor } from '.';
import { Requisicao } from '../../types/dtos';
import lang from '../../lang';

const RequisicaoItem = (props: {
    requisicao: Requisicao;
    selectedRequisicao: Optional<Requisicao>;
    key: string;
    setRequisicao: Function;
}) => {
    const className = 'menu-item divider clickable';
    const selectedClassName = className + ' selected';

    const isSelected = props.selectedRequisicao === props.requisicao;

    return (
        <span
            className={isSelected ? selectedClassName : className}
            style={{ justifyContent: 'space-between' }}
            onClick={() => {
                props.setRequisicao(props.requisicao);
            }}
        >
            <div>
                <Col>
                    <Typography weight="semi-bold" size="up-01">
                        {props.requisicao.controlador.nome}
                    </Typography>

                    <Typography size="base">{props.requisicao.numero}</Typography>
                </Col>
            </div>

            <div>
                <BrTag
                    size='small'
                    color={getTagColor(props.requisicao.status)}
                    value={lang.tr(props.requisicao.status)}
                    type="text"
                />
            </div>
        </span>
    );
};

export default RequisicaoItem;
