import lang from '../lang';

interface LabelProps {
    validate: boolean
    children: React.ReactNode,
}

const MandatoryField = ({ validate, children }: LabelProps) => {

    const style = {
        marginBottom: 20
    }

    const labelStyle = {
        fontStyle: 'italic',
        fontSize: '14px',
        color: 'red',
    }

    return (<>
        <div style={style}>
            {children}
            {validate
                ? <p style={labelStyle}>{lang.tr('campo_obrigatorio')}</p>
                : null
            }
        </div>
    </>
    )
}

export default MandatoryField;