import { BrCard, BrLoading, Row } from '@govbr-ds/react-components';
import tratamentoService from '../service/tratamentoService';
import lang from '../lang';
import { useEffect, useState } from 'react';
import { ConsultaControladorTratamento, ControladorDetalhado } from '../types/dtos';
import controladorService from '../service/controladorService';
import stringUtils from '../utils/stringUtils';
import Assets from '../assets';

interface TratamentoCardProps {
    controller: ConsultaControladorTratamento;
}

const TratamentoCard = ({ controller }: TratamentoCardProps) => {

    const [ctrlDetalhado, setCtrlDetalhado] = useState<Optional<ControladorDetalhado>>();
    const [imagem, setImagem] = useState<Optional<string>>();

    useEffect(() => {
        handleFetchData(controller.id ? controller.id : '');
    }, [controller]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchImage()
    }, [ctrlDetalhado]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [imagem])

    const handleFetchData = async (id: string) => {
        await tratamentoService.getControladorDetalhado(id).then((response) => {
            setCtrlDetalhado(response);
        });
    };

    const fetchImage = async () => {
        let idImagem = ctrlDetalhado?.idImagem ?? null;
        if (idImagem) {
            controladorService.getImagem(idImagem).then((resp) => {
                setImagem(resp);
            })
        } else {
            setImagem(Assets.SERPRO_LGPD_LOGO);
        }
    }

    const infoLine = (label: string, value: Optional<string>) => {
        return (
            <div style={{marginBottom: '6px'}}>
                <span style={{fontWeight: 'bold'}}>{label}</span>
                {value &&
                    <>
                    <span style={{fontWeight: 'bold'}}>: </span>
                    {value}
                    </>
                }
            </div>
        )
    }

    return ctrlDetalhado ? (
        <BrCard style={{ marginTop: '2rem' }}>
            <Row className="justify-center-flex">
                <div style={{ margin: '1rem 3rem 1rem 3rem' }}>
                    {imagem &&
                        <img
                            width="120px"
                            src={imagem}
                            alt={ctrlDetalhado.nome}
                        />
                    }
                </div>
                
                <div style={{ margin: '1rem' }}>

                    {infoLine(lang.tr('agente_tratamento'), ctrlDetalhado.nome)}
                    {infoLine(lang.tr('cnpj'), stringUtils.formatCnpj(ctrlDetalhado.cnpj))}

                    {ctrlDetalhado.responsavelDPO &&
                        <>
                            {ctrlDetalhado.responsavelDPO.nome &&
                                infoLine(lang.tr('encarregado'), ctrlDetalhado.responsavelDPO.nome)
                            }
                            {ctrlDetalhado.responsavelDPO.email &&
                                infoLine(lang.tr('contato'), ctrlDetalhado.responsavelDPO.email)
                            }
                        </>
                    }

                    {ctrlDetalhado.enderecoPoliticasPrivacidade &&
                        infoLine(lang.tr('politica_de_privacidade'),
                            ctrlDetalhado.enderecoPoliticasPrivacidade)
                    }

                    {ctrlDetalhado.pequenaMediaEmpresa &&
                        infoLine(lang.tr('agente_tratamento_pequeno'), undefined)
                    }

                    {/* {ctrlDetalhado.endereco &&
                        <>
                            <Typography size="up-01">
                                {ctrlDetalhado.endereco.endereco}
                            </Typography>
                            <Typography size="up-01">
                                {ctrlDetalhado.endereco.complemento}
                            </Typography>
                            <Typography size="up-01">
                                {`${ctrlDetalhado.endereco.cidade}-${ctrlDetalhado.endereco.uf
                                    }, ${lang.tr('cep')}: ${ctrlDetalhado.endereco.cep}`}
                            </Typography>
                        </>
                    } */}
                </div>
            </Row>

        </BrCard>
    ) : (
        <BrLoading />
    );
};

export default TratamentoCard;
