import {
    BrCard,
    Container,
    Col,
    Typography,
    Icon
} from '@govbr-ds/react-components';
import { Page } from '../types/page';
import PageComponent from '../components/PageComponent';
import TextMessage from '../components/TextMessage';
import lang from '../lang';
import { useEffect, useState } from 'react';
import { Controlador } from '../types/dtos';
import controladorService from '../service/controladorService';
import stringUtils from '../utils/stringUtils';

interface OrganizationCardProps {
    name: string;
    termsOfUseUrl?: string;
    privacyPolicyUrl: string;
    image: string;
}

const OrganizationCardLink = (props: { title: string; url: string }) => {
    const [link, setLink] = useState<string>()
    const HTTP = "http://";
    const HTTPS = "https://";

    useEffect(() => {
        if (!props.url.startsWith(HTTP) && !props.url.startsWith(HTTPS)) {
            setLink(HTTPS + props.url)
        } else {
            setLink(props.url)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [link])

    return (
        <Col margin={2}>
            <Typography size="up-01">{`${props.title}:`}</Typography>
            <Icon icon="fa fa-fw fa-link"></Icon>
            <a
                target="_blank"
                href={link}
                style={{ wordBreak: 'break-all' }}
                rel="noreferrer"
            >
                {link}
            </a>
        </Col>
    )
};

const OrganizationCard = (props: OrganizationCardProps) => {
    const [imagem, setImagem] = useState<string>();

    useEffect(() => {
        fetchImg()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        
    }, [imagem])

    const fetchImg = async () => {
        controladorService.getImagem(props.image).then((resp) => {
            setImagem(resp)
        })
    }

    return (
        <BrCard>
            <Container flex={true} column={false} alignItems="center">
                <Col alignSelf="center" margin={3}>
                    {imagem &&
                        <img
                            width="80px"
                            src={imagem}
                            alt={props.name}
                        />
                    }
                </Col>

                <div>
                    <Typography weight="semi-bold" size="up-02">
                        {props.name}
                    </Typography>

                    {props.termsOfUseUrl && (
                        <OrganizationCardLink
                            title={lang.tr('termos_de_uso')}
                            url={props.termsOfUseUrl}
                        />
                    )}

                    <OrganizationCardLink
                        title={lang.tr('politica_de_privacidade')}
                        url={props.privacyPolicyUrl}
                    />
                </div>
            </Container>
        </BrCard>
    )
}

const PoliticasPrivacidadePage = () => {
    let [controllers, setControllers] = useState<Controlador[]>([])

    useEffect(() => {
        fetch()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [controllers])

    const fetch = async () => {
        controladorService.getControladores().then((resp) => {
            let list: Controlador[] = resp.filter(c =>
                !stringUtils.isNullOrEmpty(c.enderecoTermoDeUso)
                || !stringUtils.isNullOrEmpty(c.enderecoPoliticasPrivacidade)
            )
            setControllers(list);
        })
    }

    return (
        <PageComponent page={Page.POLITICAS}>
            <TextMessage
                type="info"
                message={lang.tr('politicas_privacidade_desc_pagina')}
            />

            {controllers.map((controller: Controlador, index: number) => (
                <div key={controller.id}>
                    <OrganizationCard
                        name={controller.razaoSocial}
                        termsOfUseUrl={controller.enderecoTermoDeUso}
                        privacyPolicyUrl={controller.enderecoPoliticasPrivacidade || ""}
                        image={controller.idImagem}
                    />
                </div>
            ))}
        </PageComponent>
    );
};

export default PoliticasPrivacidadePage;
