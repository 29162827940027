import Cookies, { CookieSetOptions } from 'universal-cookie';
import stringUtils from '../utils/stringUtils';

const cookies = new Cookies(null, { path: '/' });

const storage = {
    TOKEN: 'tkn',
    SESSION_INFO: 'si',
    NAVIGATION: 'nstat',

    set(key: string, value: string) {
        const encrypted = stringUtils.encrypt(value);
        localStorage.setItem(key, encrypted);
        window.dispatchEvent(new Event(key))
    },

    fetch(key: string): Nullable<string> {
        const value = localStorage.getItem(key);
        return stringUtils.decrypt(value);
    },

    clear(key?: Optional<string>) {
        if (key === undefined) {
            localStorage.clear();
        } else {
            localStorage.removeItem(key);
        }
        window.dispatchEvent(new Event(key ?? this.SESSION_INFO))
    },

    getCookie(key: string): Nullable<string> {
        const cookieValue = cookies.get(key);
        if (typeof cookieValue == 'string') {
            return cookieValue;
        }
        return null;
    },

    setCookie(value: string, options: CookieSetOptions) {
        cookies.set(this.TOKEN, value, options);
        window.dispatchEvent(new Event(this.TOKEN))
    },

    clearCookie() {
        cookies.remove(this.TOKEN);
        window.dispatchEvent(new Event(this.TOKEN))
    }
};

export default storage;
