import i18next from './i18n';
import { Lang, defaultNS } from './i18n';

i18next.changeLanguage(Lang.pt_BR);

const lang = {
    tr(text: string, options?: { [key: string]: string }) {
        return i18next.t(defaultNS + ':' + text, text, options);
    },

    language(tr: string) {
        i18next.changeLanguage(tr);
    },
}

export default lang;