import { usePageContext } from '../context/pageContext';
import '../style/toast.css';
import { useEffect } from 'react';
import popup from '../common/popup';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import Assets from '../assets';

const Toast = () => {
    const { showPopup, setShowPopup } = usePageContext();

    useEffect(() => {
        if (showPopup) {
            popup.show(showPopup);
            setShowPopup(undefined)
        }
    }, [showPopup]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Toaster>
            {(t) => (
                <ToastBar toast={t}>
                {({ icon, message }) => (
                    <>
                    {icon}
                    {message}
                    {t.type !== 'loading' && (
                        <img className='close-icon' src={Assets.CLOSE} onClick={() => toast.dismiss(t.id)} alt={'Fechar'}/>
                    )}
                    </>
                )}
                </ToastBar>
            )}
        </Toaster>
    )
}

export default Toast;
