import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

const stringUtils = {
    isNullOrEmpty(str: string | null | undefined): boolean {
        return !(str && str.length > 0)
    },

    uniqueId(): string {
        return uuidv4();
    },

    removeLastChar(str: string) {
        if (!this.isNullOrEmpty(str))
            return str.substring(0, str.length - 1);
        return str
    },

    formatDate(dateString: string): string {
        const formatedDate = new Date(dateString).toLocaleString('pt-BR');
        return formatedDate === 'Invalid Date' ? dateString : formatedDate;
    },

    formatCnpj(cnpj: string): string {
        return cnpj.length !== 14
            ? cnpj
            : cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },

    formatCpf(cpf: string): string {
        return cpf.length !== 11
            ? cpf
            : cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    encrypt(text: Nullable<string>): string {
        const secret: string = process.env.REACT_APP_SECRET_KEY ?? 'REACT_APP_SECRET_KEY'
        if (text) {
            return CryptoJS.AES.encrypt(text, secret).toString();
        }
        return ''
    },

    decrypt(text: Nullable<string>): string {
        const secret: string = process.env.REACT_APP_SECRET_KEY ?? 'REACT_APP_SECRET_KEY'
        if (text) {
            var bytes  = CryptoJS.AES.decrypt(text, secret);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return ''
    },
}

export default stringUtils