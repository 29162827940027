import { BrModal, BrTextarea, Typography } from '@govbr-ds/react-components';
import { Reclamacao } from '../../types/dtos';
import lang from '../../lang';
import { ChangeEvent, useEffect, useState } from 'react';
import reclamacoesService from '../../service/reclamacoesService';
import { usePageContext } from '../../context/pageContext';
import popup, { PopupMessage } from '../../common/popup';
import '../../style/modal.css'
import stringUtils from '../../utils/stringUtils';

const ModalReclamacaoResp = (props: { reclamacao: Reclamacao, onDismiss: (refresh: boolean) => void }) => {
    const { setShowPopup } = usePageContext();

    const [reclamacao] = useState<Reclamacao>(props.reclamacao)
    const [resposta, setResposta] = useState<Optional<string>>()
    const [validResp, setValidResp] = useState<boolean>();

    useEffect(() => {

    }, [resposta, validResp])

    const onDescriptionChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let text = event.target.value
        setResposta(text)
        setValidResp(!stringUtils.isNullOrEmpty(text))
    }

    const submit = () => {
        if (!stringUtils.isNullOrEmpty(resposta)) {
            reclamacoesService.responder(reclamacao.id, resposta!).then((resp) => {
                let popupMsg: PopupMessage = resp
                    ? popup.successConfig(lang.tr('devolve_reclamacao_ok'))
                    : popup.errorConfig(lang.tr('devolve_reclamacao_erro'))
                setShowPopup(popupMsg)
                props.onDismiss(true)
            })
        }
    }

    return (
        <BrModal title={lang.tr('devolver_reclamacao')}
            minWidth={500}
            isOpen={true}
            secondaryAction={{ label: lang.tr('cancelar').toUpperCase(), action: () => props.onDismiss(false) }}
            primaryAction={{ label: lang.tr('enviar').toUpperCase(), disabled: !validResp, action: () => submit() }}
            onClose={() => props.onDismiss(false)}>
            {
                <div className='modal-content'>
                    <br />
                    <Typography size="up-01">
                        {lang.tr('requisicao_nro')}
                    </Typography>
                    <Typography weight="semi-bold" size="up-01">
                        {reclamacao.numero}
                    </Typography>
                    <br />
                    <BrTextarea id="textarea-id1"
                        label={lang.tr('descreva_resposta')}
                        placeholder={lang.tr('descreva_resposta')}
                        onChange={(evt) => onDescriptionChanged(evt)}
                    ></BrTextarea>
                </div>
            }
        </BrModal>
    );
};

export default ModalReclamacaoResp;
