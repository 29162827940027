export enum Page {
    SPLASH = 'SPLASH',
    HOME = 'HOME',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    CONSENTIMENTO = 'CONSENTIMENTO',
    INFO_TRATAMENTO = 'INFO_TRATAMENTO',
    INFO_TRATAMENTO_DETALHADO = 'INFO_TRATAMENTO_DETALHADO',
    RECLAMACOES = 'RECLAMACOES',
    NOVA_RECLAMACAO = 'NOVA_RECLAMACAO',
    NOVA_RECLAMACAO_FORM = 'NOVA_RECLAMACAO_FORM',
    REQUISICOES = 'REQUISICOES',
    NOVA_REQUISICAO = 'NOVA_REQUISICAO',
    POLITICAS = 'POLITICAS',
}