import '../../style/style.css';
import { useEffect, useState } from 'react';
import { Col, BrLoading, BrModal, BrMessage } from '@govbr-ds/react-components';
import { Page } from '../../types/page';
import PageComponent from '../../components/PageComponent';
import ConsentsMenu from './ConsentsMenu';
import ConsentCard from './ConsentCard';
import consentimentosService, {
    Consentimento,
} from '../../service/consentimentosService';
import { ConsultaConsentTitular } from '../../types/dtos';
import { StatusConsentimento } from '../../types/enums';
import lang from '../../lang';
import { usePageContext } from '../../context/pageContext';
import popup, { PopupMessage } from '../../common/popup';

interface ModalConfirmStatus {
    title: string;
    consequence?: Nullable<string>;
    status: StatusConsentimento;
}

const ConsentimentosPage = () => {
    const { setShowPopup } = usePageContext();

    const [consentimentos, setConsentimentos] = useState(
        undefined as Optional<ConsultaConsentTitular[]>
    );

    const [consentimento, setConsentimento] = useState(
        undefined as Optional<Consentimento>
    );

    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState<Optional<ModalConfirmStatus>>();

    // ---------- Data handling ----------

    useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [consentimentos, consentimento, loading, showConfirm])

    const fetchData = async () => {
        setLoading(true)
        await consentimentosService.getConsentimentos().then((response) => {
            setConsentimentos(response);
            if (consentimento) {
                let filter = response.filter(c => c.id === consentimento.id)
                setConsentimento(filter.length > 0 ? filter[0] : undefined)
            }
            setLoading(false)
        });
    };

    const onUpdate = (status: StatusConsentimento) => {
        if (consentimento) {
            if (status === StatusConsentimento.NEGADO) {
                setShowConfirm({
                    title: lang.tr('confirma_negacao_consentimento'),
                    consequence: consentimento.consequenciaRevogacao,
                    status: status
                })
            } else if (status === StatusConsentimento.REVOGADO) {
                setShowConfirm({
                    title: lang.tr('confirma_revogacao_consentimento'),
                    status: status
                })
            } else {
                confirmUpdate(status)
            }
        }
    }

    const confirmUpdate = (status: StatusConsentimento) => {
        if (consentimento) {
            consentimentosService.updateStatus(consentimento.id, status).then((success) => {
                let message: string = ''
                if (status === StatusConsentimento.NEGADO) {
                    message = lang.tr(success ? 'consent_negado_ok' : 'consent_negado_erro');
                } else if (status === StatusConsentimento.REVOGADO) {
                    message = lang.tr(success ? 'consent_revogado_ok' : 'consent_revogado_erro');
                } else {
                    message = lang.tr(success ? 'consent_aprovado_ok' : 'consent_aprovado_erro');
                }

                let popupMsg: PopupMessage = success
                ? popup.successConfig(message)
                : popup.errorConfig(message)
                setShowPopup(popupMsg)
            
                fetchData()
            });
        }
    }

    // ---------- Render ----------

    const ConsentPanel = () => {
        if ((consentimentos ?? []).length > 0) {
            return <div className="listcard-container">
                <Col>
                    <ConsentsMenu
                        consentimentos={consentimentos ?? []}
                        selectedConsentimento={consentimento}
                        setConsentimento={setConsentimento}
                    />
                </Col>

                <Col>
                    {consentimento && (
                        <ConsentCard consentimento={consentimento} onUpdate={(status) => onUpdate(status)} />
                    )}
                </Col>
            </div>
        }
        return <>
            <BrMessage type={'info'} message={lang.tr('consents_not_found')}></BrMessage>
            <br />
        </>
    }

    return (
        <PageComponent page={Page.CONSENTIMENTO}>
            {loading
                ? <BrLoading />
                : ConsentPanel()
            }

            <BrModal title={showConfirm?.title ?? ''}
                minWidth={500}
                isOpen={showConfirm !== undefined}
                secondaryAction={{ label: lang.tr('cancelar'), action: () => setShowConfirm(undefined) }}
                primaryAction={{ label: lang.tr('confirmar'), action: () => showConfirm ? confirmUpdate(showConfirm?.status) : {} }}
                onClose={() => setShowConfirm(undefined)}>
                {
                    showConfirm?.consequence
                        ? <div style={{ margin: '30px 10px', }}>
                            <b>{lang.tr('consequencia_')}</b> {showConfirm.consequence}
                        </div>
                        : null
                }
            </BrModal>

        </PageComponent>
    );
};

export default ConsentimentosPage;
