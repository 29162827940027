import {
    BrButton,
    BrLoading,
    Col,
    Typography
} from '@govbr-ds/react-components';
import { Page } from '../../types/page';
import { useEffect, useState } from 'react';
import reclamacoesService from '../../service/reclamacoesService';
import lang from '../../lang';
import { usePageContext } from '../../context/pageContext';
import { Reclamacao } from '../../types/dtos';
import PaginatedList from '../../components/PaginatedList';
import ComplaintItem from './ComplaintItem';
import PageComponent from '../../components/PageComponent';
import ComplaintCard from './ComplaintCard';
import ModalReclamacaoResp from './ModalReclamacaoResp';
import { ReclamacaoStatus } from '../../types/enums';

const ComplaintsNotFound = () => {
    const { openPage } = usePageContext();

    return (
        <center>
            <Typography weight="semi-bold" size="up-02">
                {lang.tr('complaints_not_found')}
            </Typography>
            <br />
            <BrButton
                secondary
                onClick={() => {
                    openPage(Page.NOVA_RECLAMACAO);
                }}
            >
                {lang.tr('nova_reclamacao')}
            </BrButton>
        </center>
    );
};

const ReclamacoesPage = () => {
    const { openPage } = usePageContext();

    const [complaints, setComplaints] = useState<Optional<Reclamacao[]>>();
    const [loading, setLoading] = useState<boolean>();
    const [selected, setSelected] = useState<Optional<Reclamacao>>();
    const [showDevolut, setShowDevolut] = useState<boolean>();

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {

    }, [loading, showDevolut, selected?.status])

    const fetch = async () => {
        setLoading(true);
        reclamacoesService.getReclamacoes().then((reclamacoes) => {
            setComplaints(reclamacoes);
            setLoading(false);
            if (reclamacoes.length > 0) {
                setSelected(reclamacoes[0])
            }
        });
    };

    const complaintItemBuilder = (reclamacao: Reclamacao) => (
        <ComplaintItem
            key={reclamacao.id}
            reclamacao={reclamacao}
            selected={selected}
            setReclamacao={setSelected}
        />
    );

    const newComplaint = () => {
        openPage(Page.NOVA_RECLAMACAO);
    }

    const onModalDismissed = (refresh: boolean) => {
        setShowDevolut(false)
        if (refresh) {
            fetch()
        }
    }

    const onUpdate = (status: ReclamacaoStatus) => {
        if (status === ReclamacaoStatus.INFORMACAO_SOLICITADA) {
            setShowDevolut(true);
        } else {
            // nothing
        }
    }

    const renderComplaints = () => {
        if (complaints === undefined) {
            return <BrLoading />;
        }
        if (complaints.length === 0) {
            return <ComplaintsNotFound />;
        }
        return (
            <div className="listcard-container">
                <Col>
                    <PaginatedList
                        items={complaints!}
                        selectedItem={selected}
                        setSelectedItem={setSelected}
                        itemBuilder={complaintItemBuilder}
                    />
                </Col>

                <Col>
                    {selected && (
                        <ComplaintCard complaint={selected} onUpdate={onUpdate} />
                    )}
                </Col>

                {selected && showDevolut &&
                    <ModalReclamacaoResp
                        reclamacao={selected}
                        onDismiss={(refresh) => onModalDismissed(refresh)} />
                }
            </div>
        );
    };

    return (
        <PageComponent page={Page.RECLAMACOES}>
            {loading
                ? <BrLoading />
                : <>
                    {renderComplaints()}
                    <center>
                        <BrButton primary onClick={newComplaint}>{lang.tr('nova_reclamacao')}</BrButton>
                    </center>
                </>
            }
        </PageComponent>
    )
};

export default ReclamacoesPage;
