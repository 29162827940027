import { BrSelect, BrMessage, Typography, BrLoading } from "@govbr-ds/react-components"
import { Page } from '../../types/page';
import PageComponent from "../../components/PageComponent";
import TextMessage from "../../components/TextMessage";
import lang from "../../lang";
import { useEffect, useState } from "react";
import TratamentoLayout from "../../types/tratamento";
import TratamentoCard from "../../components/TratamentoCard";
import { ConsultaControladorTratamento } from "./../../types/dtos"
import tratamentoService from "./../../service/tratamentoService";
import InfoTratamentoList from "./InfoTratamentoList";

const InfoTratamentoPage = () => {
    const [loading, setLoading] = useState(true)
    const [loadingLayout, setLoadingLayout] = useState(false)
    const [controllers, setControllers] = useState<Optional<ConsultaControladorTratamento[]>>()
    const [controller, setController] = useState<Optional<ConsultaControladorTratamento>>();
    const [tratamentos, setTratamentos] = useState<TratamentoLayout[] | null>(null)

    useEffect(() => {
        fetchControllers();
    }, []);

    useEffect(() => {
        fetchTratamentos()
    }, [controller]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchControllers = async () => {
        setLoading(true)
        tratamentoService.getControladoresTratamento().then((response) => {
            setControllers(response);
            setLoading(false)
        });
    };

    const fetchTratamentos = async () => {
        if (controller !== null && controller !== undefined) {
            setLoadingLayout(true);
            tratamentoService.getTratamentos(controller?.id).then((response) => {
                setTratamentos(response);
                setLoadingLayout(false);
            });
        }
    };

    const renderTratamentos = () => {
        if (controllers === null || controllers === undefined) {
            return <BrMessage type='danger' message={lang.tr('tratamento_indisponivel')}></BrMessage>
        }
        if (controllers == null) {
            return (
                <center>
                    <Typography weight="semi-bold" size="up-02">
                        {lang.tr('tratamento_indisponivel')}
                    </Typography>
                </center>
            )
        }

        return (
            <BrSelect
                label={lang.tr('selecione_agente_tratamento')}
                placeholder={lang.tr('selecione')}
                options={controllers.map((c) => (c.nome))}
                value={lang.tr('selecione')}
                onClick={({ currentTarget: { value } }) => {
                    const contr = controllers?.find
                        (c => c.nome === value)
                    setController(contr);
                }
                }
            />
        )
    }

    return (
        <PageComponent page={Page.INFO_TRATAMENTO}>
            <TextMessage
                type="info"
                message={lang.tr('consulta_transparencia_info')}
            />
            {loading
                ? <BrLoading />
                : <>
                    {renderTratamentos()}
                    {controller && <TratamentoCard controller={controller} />}
                    {loadingLayout
                    ? <BrLoading/>
                    : tratamentos && <InfoTratamentoList infoTratamento={tratamentos} />
                    }
                </>
            }

        </PageComponent>
    )
}
export default InfoTratamentoPage;