import { MouseEventHandler, useState } from 'react';

interface ButtonProps {
    onClick: MouseEventHandler;
}

interface PageButtonProps extends ButtonProps {
    pageNumber: number;
    isSelected: boolean;
}

interface EllipsisButtonProps {
    pageNumbers: number[];
    setPage: Function;
}

export const PreviousPageButton = ({ onClick }: ButtonProps) => (
    <li>
        <button
            className="br-button circle"
            type="button"
            data-previous-page="data-previous-page"
            onClick={onClick}
        >
            <i className="fas fa-angle-left" aria-hidden="true"></i>
        </button>
    </li>
);

export const NextPageButton = ({ onClick }: ButtonProps) => (
    <li>
        <button
            className="br-button circle"
            type="button"
            data-next-page="data-next-page"
            onClick={onClick}
        >
            <i className="fas fa-angle-right" aria-hidden="true"></i>
        </button>
    </li>
);

export const PageButton = ({
    pageNumber,
    isSelected,
    onClick
}: PageButtonProps) => (
    <li>
        <span
            className={isSelected ? 'page clickable active' : 'page clickable'}
            aria-current="page"
            onClick={onClick}
        >
            {pageNumber}
        </span>
    </li>
);

export const EllipsisButton = ({
    pageNumbers,
    setPage
}: EllipsisButtonProps) => {
    const [hidden, setHidden] = useState(true);

    return (
        <li className="pagination-ellipsis dropdown">
            <button
                className="br-button circle"
                type="button"
                data-toggle="dropdown"
                onClick={() => setHidden(!hidden)}
            >
                <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
            </button>

            <div className="br-list" role="menu" hidden={hidden}>
                {pageNumbers.map((pageNumber) => (
                    <span
                        className="br-item"
                        role="menuitem"
                        key={pageNumber}
                        onClick={() => setPage(pageNumber)}
                    >
                        {pageNumber}
                    </span>
                ))}
            </div>
        </li>
    );
};
