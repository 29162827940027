import TratamentoLayout from "../types/tratamento";
import { ConsultaControladorTratamento, ControladorDetalhado, VinculoTratamento } from "../types/dtos"
import api from "../api";

export interface TratamentoResponse {
    success: boolean;
    data?: TratamentoLayout[];
}

export interface ControladoresTratamentoResponse {
    success: boolean;
    data?: ConsultaControladorTratamento[];
}

const tratamentoService = {
    async getControladoresTratamento(): Promise<ConsultaControladorTratamento[]> {
        let resp = await api.getControladoresTratamento();
        if (resp.success) {
            return resp.data as ConsultaControladorTratamento[]
        }
        return []
    },

    async getControladorDetalhado(id: string): Promise<Optional<ControladorDetalhado>> {
        let resp = await api.getControlador(id);
        if (resp.success) {
            return resp.data as ControladorDetalhado
        }
        return undefined
    },

    async getTratamentos(id: string): Promise<TratamentoLayout[]> {
        let params: Map<string, string> = new Map<string, string>();
        params.set("idControlador", id)
        let resp = await api.getTratamentos(params);
        if (resp.success) {
            return resp.data as TratamentoLayout[]
        }
        return []
    },

    async getVinculoTratamento(id: string): Promise<Optional<VinculoTratamento>> {
        let resp = await api.getVinculoTratamento(id);
        if (resp.success) {
            return resp.data as VinculoTratamento
        }
        return undefined
    },
}

export default tratamentoService;
