import toast from 'react-hot-toast';
import '../style/toast.css';
import Assets from '../assets';

export interface PopupMessage {
    text: string,
    type: PopupType,
}

export enum PopupType {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    DEFAULT = 'default',
}

const popup = {

    successConfig(text: string): PopupMessage {
        return { text: text, type: PopupType.SUCCESS }
    },

    errorConfig(text: string): PopupMessage {
        return { text: text, type: PopupType.ERROR }
    },

    infoConfig(text: string): PopupMessage {
        return { text: text, type: PopupType.INFO }
    },

    warningConfig(text: string): PopupMessage {
        return { text: text, type: PopupType.WARNING }
    },

    show(message: PopupMessage) {
        let icon = '';
        let style = {};
        switch (message.type) {
            case PopupType.SUCCESS:
                icon = Assets.SUCCESS;
                style = styles.success;
                break;
            case PopupType.INFO:
                icon = Assets.INFO;
                style = styles.info;
                break;
            case PopupType.WARNING:
                icon = Assets.WARNING;
                style = styles.warning;
                break;
            case PopupType.ERROR:
                icon = Assets.ERROR;
                style = styles.error;
                break;
            default:
                style = messageStyle;
                break;
        }

        toast(message.text, {
            style: style,
            position: "top-center",
            duration: 5000,
            icon: <img style={styles.icon} src={icon} alt={'mensagem'}/>
        })
    }
}

// ===== Styles =====

const messageStyle = {
    background: 'grey',
    color: '#fff',
    fontSize: '14px',
    padding: '20px 10px',
    width: '500px',
}

const styles = {
    

    info: {
        ...messageStyle,
        background: '#155bcb',
        color: '#fff',
    },

    success: {
        ...messageStyle,
        background: '#168821',
        color: '#fff',
    },

    error: {
        ...messageStyle,
        background: '#e52207',
        color: '#fff',
    },

    warning: {
        ...messageStyle,
        background: '#f59f00',
        color: '#fff',
    },

    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        height: '24px',
    },

    closeIcon: {
        top: 0,
        right: 0,
        color: '#FFFFFF',
        height: '10px',
        cursor: 'pointer',
    },
}

export default popup;