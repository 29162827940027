import { Icon, Row, Typography } from '@govbr-ds/react-components';
import lang from '../lang';

export interface RatingProps {
    rating: Optional<number>;
    description: Optional<string>;
}

const Rating = ({ rating, description }: RatingProps) => {
    const MAX_SCORE = 5;

    if (rating == null) {
        return <></>;
    }

    const getStarIcon = (index: number) => {
        return index < rating
            ? 'fa fa-star text-warning'
            : 'fa fa-star disabled';
    };

    return (
        <Row
            className="card-footer"
            column={true}
        >
            <Typography weight="bold" size="up-01">
                {lang.tr('avaliacao_cidadao')}
            </Typography>

            <div style={{ display: 'block' }}>
                {[...Array(MAX_SCORE)].map((_, index) => (
                    <Icon key={index} icon={getStarIcon(index)} />
                ))}
            </div>

            <div>
                <p>{description}</p>
            </div>
        </Row>
    );
};

export default Rating;