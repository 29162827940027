import { BrCard, BrTag } from '@govbr-ds/react-components';
import lang from '../lang';
import { useEffect, useState } from 'react';
import { Page } from '../types/page';
import '../style/style.css';
import '../style/card.css';
import Assets from '../assets';
import PageComponent from '../components/PageComponent';
import { usePageContext } from '../context/pageContext';
import consentimentosService from '../service/consentimentosService';
import requisicoesService from '../service/requisicoesService';
import reclamacoesService from '../service/reclamacoesService';

interface CardComponentProps {
    title: string;
    description: string;
    icon: string;
    page: Page;
}

const getPendingNumber = async (page: Page) => {
    switch (page) {
        case Page.CONSENTIMENTO:
            let consentSumario = await consentimentosService.getConsentimentosSumario();
            return consentSumario?.qtdConsentimentoPendente ?? 0;
        case Page.REQUISICOES:
            let reqSumario = await requisicoesService.getRequisicoesSumario();
            return reqSumario?.qtdRequisicaoPendente ?? 0;
        case Page.RECLAMACOES:
            let reclSumario = await reclamacoesService.getReclamacoesSumario();
            return reclSumario?.qtdReclamacaoPendente ?? 0;
        default:
            return 0;
    }
};

const CardComponent = ({
    title,
    page,
    icon,
    description
}: CardComponentProps) => {
    const [pendingNumber, setPendingNumber] = useState<number>(0);
    const { openPage } = usePageContext();

    const cardStyle = {
        width: '80%',
        minWidth: 300,
        height: 250,
        margin: 10
    };

    const thumbnail = {
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    };

    useEffect(() => {
        getPendingNumber(page).then((value: number) => {
            setPendingNumber(value);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BrCard
            key={title}
            className={'card'}
            style={cardStyle}
            onClick={() => openPage(page)}
        >
            {pendingNumber > 0 ?
                <div style={{ textAlign: 'end', fontSize: 'large', padding: '10px' }}>
                    <BrTag
                        style={{ position: 'absolute' }}
                        type="count"
                        value={pendingNumber.toString()}
                        color="warning"
                        size="large"
                    />
                </div>
                : null
            }
            <img style={thumbnail} src={icon} alt={lang.tr(title)} />
            <h4>{lang.tr(title)}</h4>
            <p>{lang.tr(description)}</p>
        </BrCard>
    );
};

const HomePage = () => {
    const cards = [
        { title: 'consentimentos', description: 'solicitacoes_consentimento', icon: Assets.CONSENTIMENTO, page: Page.CONSENTIMENTO },
        { title: 'requisicoes', description: 'requisicoes_titular', icon: Assets.CHECK_CIRCLE_SOLID, page: Page.REQUISICOES },
        { title: 'reclamacoes', description: 'reclamacoes_titular', icon: Assets.BULLHORN, page: Page.RECLAMACOES },
        { title: 'tratamentos', description: 'informacoes_tratamento', icon: Assets.DATABASE, page: Page.INFO_TRATAMENTO },
    ];

    return (
        <PageComponent page={Page.HOME} fullPage>
            <div className={'card-panel-hz'}>
                {cards.map((card) => {
                    return <CardComponent {...card} key={card.title} />;
                })}
            </div>
        </PageComponent>
    );
};

export default HomePage;
