import {
    Controlador,
    DevolucaoReclamacao,
    ImagemReclamacao,
    NovaReclamacaoDTO,
    Reclamacao,
    SumarioReclamacaoTitular
} from '../types/dtos';
import api from '../api';
import { HttpResponse } from '../api/types';
import { FileUploadData } from '../types/fileUpload';
import stringUtils from '../utils/stringUtils';

const reclamacoesService = {
    async getReclamacoesSumario(): Promise<Nullable<SumarioReclamacaoTitular>> {
        const resp = await api.getSumarioReclamacoes();
        if (resp.success) {
            return resp.data as SumarioReclamacaoTitular;
        }
        return null;
    },

    async getReclamacoes(): Promise<Reclamacao[]> {
        const resp = await api.getReclamacoes();
        if (resp.success) {
            return resp.data as Reclamacao[];
        }
        return [];
    },

    async getControladores(): Promise<Controlador[]> {
        const resp = await api.getReclamacoesControladores();
        if (resp.success) {
            return resp.data as Controlador[];
        }
        return [];
    },

    async novaReclamacao(idCtrl: string, detalhe: string, files: FileUploadData[]): Promise<HttpResponse> {

        let params: NovaReclamacaoDTO = {
            idControlador: idCtrl,
            detalhe: detalhe,
        }

        let images: ImagemReclamacao[] = []
        files.forEach(img => {
            images.push({
                base64: img.base64,
                idImagem: stringUtils.uniqueId(),
                mimeType: img.type,
            })
        });

        if (images.length > 0) {
            params = { ...params, imagens: images }
        }

        let resp = await api.novaReclamacao(params);
        return resp
    },

    async responder(id: string, detalhe: string): Promise<boolean> {
        let params: DevolucaoReclamacao = {
            idReclamacao: id,
            detalhe: detalhe
        }
        let resp: HttpResponse = await api.devolverReclamacao(params);
        return resp.success
    },
};

export default reclamacoesService;
