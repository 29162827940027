// import { BrColor } from "@govbr-ds/react-components/dist/types/colors";

enum Colors {
    primary = "#0c326f",
    lightGray = "#f8f8f8",
    link = "#1351B4",
}

enum BrColors {
    success = "success",
    info = "info",
    warning = "warning",
    danger = "danger",
    primary_default = "primary-default",
    primary_pastel_01 = "primary-pastel-01",
    primary_pastel_02 = "primary-pastel-02",
    primary_lighten_01 = "primary-lighten-01",
    primary_lighten_02 = "primary-lighten-02",
    primary_darken_01 = "primary-darken-01",
    primary_darken_02 = "primary-darken-02",
    secondary_01 = "secondary-01",
    secondary_02 = "secondary-02",
    secondary_03 = "secondary-03",
    secondary_04 = "secondary-04",
    secondary_05 = "secondary-05",
    secondary_06 = "secondary-06",
    secondary_07 = "secondary-07",
    secondary_08 = "secondary-08",
    secondary_09 = "secondary-09",
    highlight = "highlight",
    support_01 = "support-01",
    support_02 = "support-02",
    support_03 = "support-03",
    support_04 = "support-04",
    support_05 = "support-05",
    support_06 = "support-06",
    support_07 = "support-07",
    support_08 = "support-08",
    support_09 = "support-09",
    support_10 = "support-10",
    support_11 = "support-11",
};

export { Colors, BrColors };
