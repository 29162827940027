import lang from '../lang';
import AppRoutes from '../api/app-routes';
import { Menu, MenuProps } from './Menu';
import { Page } from '../types/page';

const menuItems = [
    {
        name: AppRoutes.getTitle(Page.HOME),
        page: Page.HOME
    },
    {
        name: AppRoutes.getTitle(Page.CONSENTIMENTO),
        page: Page.CONSENTIMENTO
    },
    {
        name: lang.tr('requisicao_privacidade'),
        children: [
            {
                name: AppRoutes.getTitle(Page.NOVA_REQUISICAO),
                page: Page.NOVA_REQUISICAO
            },
            {
                name: AppRoutes.getTitle(Page.REQUISICOES),
                page: Page.REQUISICOES
            }
        ]
    },
    {
        name: lang.tr('reclamacoes'),
        children: [
            {
                name: AppRoutes.getTitle(Page.NOVA_RECLAMACAO),
                page: Page.NOVA_RECLAMACAO
            },
            {
                name: AppRoutes.getTitle(Page.RECLAMACOES),
                page: Page.RECLAMACOES
            }
        ]
    },
    {
        name: AppRoutes.getTitle(Page.INFO_TRATAMENTO),
        page: Page.INFO_TRATAMENTO
    },
    {
        name: AppRoutes.getTitle(Page.POLITICAS),
        page: Page.POLITICAS
    },
];

const DefaultMenu = (props: MenuProps) => (
    <Menu {...props} menuItems={menuItems} />
);

export default DefaultMenu;
