import {
    BrButton,
    BrCard,
    BrItem,
    BrTag,
    Container,
    Icon,
    Row,
    Typography
} from '@govbr-ds/react-components';
import lang from '../../lang';
import { getTagColor } from '.';
import { HistoricoReclamacao, Reclamacao } from '../../types/dtos';
import { ReactNode, useEffect } from 'react';
import Rating from '../../components/Rating';
import '../../style/info.css';
import stringUtils from '../../utils/stringUtils';
import { ReclamacaoStatus } from '../../types/enums';

// ---------- Interface ----------

interface ComplaintInfoProps {
    label: string;
    value?: string;
    icon?: string;
    children?: ReactNode;
}

interface ComplaintCardProps {
    complaint: Reclamacao;
    onUpdate: (status: ReclamacaoStatus) => void;
}

// ------------ Render ------------

const ComplaintInfo = ({
    label,
    value,
    icon,
    children
}: ComplaintInfoProps) => {
    return (
        <div className={'info-panel'}>
            <Typography htmlElement="p" weight="bold" className="mb-1n">
                {label}
            </Typography>
            {icon && <Icon icon={icon} />}
            {value ? <span className='info-text'>{value}</span> : children}
        </div>
    );
};

const ComplaintCard = ({ complaint, onUpdate }: ComplaintCardProps) => {

    useEffect(() => {

    }, [complaint])

    const orderedHistory = () => {
        return complaint.historico ?
            complaint.historico.sort((a, b) => b.dataHora.localeCompare(a.dataHora)) : [];
    }

    const mapHistory = () =>
        orderedHistory().map((item: HistoricoReclamacao, index: number) => {
            return (
                <BrItem
                    className="br-item"
                    divider={true}
                    key={index}
                    density="media"
                >
                    <Container>
                        <Row justifyContent="between">
                            <Typography htmlElement="span" weight="semi-bold">
                                {item.labelText}
                            </Typography>
                            <BrTag size={'small'} style={{ fontSize: 12 }}
                                color={getTagColor(item.status)}
                                value={lang.tr(item.status)}
                                type="text"
                            />
                        </Row>
                        <br />
                        <Typography htmlElement="p" className="mb-1n">
                            {item.text}
                            <br />
                            {stringUtils.formatDate(item.dataHora)}
                        </Typography>
                    </Container>
                </BrItem>

            );
        });

    return (
        <BrCard style={{ padding: '1rem' }}>
            <Row className="card-header" justifyContent="between">
                <Typography weight="bold" size="up-02">
                    {complaint.controlador.nome}
                </Typography>

                <BrTag
                    color={getTagColor(complaint.status)}
                    value={lang.tr(complaint.status)}
                    type="text"
                />
            </Row>
            <Row className="card-content">
                <div style={{ width: '100%' }}>
                    <ComplaintInfo
                        label={lang.tr('numero')}
                        value={complaint.numero}
                    />

                    {complaint.detalhe && (
                        <ComplaintInfo
                            label={lang.tr('detalhe')}
                            value={stringUtils.formatDate(complaint.detalhe)}
                        />
                    )}

                    {complaint.dataSolicitacao && (
                        <ComplaintInfo
                            label={lang.tr('data_solicitacao')}
                            value={stringUtils.formatDate(complaint.dataSolicitacao)}
                            icon="fa fa-calendar-alt"
                        />
                    )}

                    {complaint.dataConclusao && (
                        <ComplaintInfo
                            label={lang.tr('data_conclusao')}
                            value={stringUtils.formatDate(complaint.dataConclusao)}
                            icon="fa fa-calendar-alt"
                        />
                    )}

                    {complaint.historico && complaint.historico.length > 0 && (
                        <ComplaintInfo label={lang.tr('historico')}>
                            <div
                                className="br-list shadow-sm-up"
                                style={{
                                    maxHeight: '30rem',
                                    overflow: 'scroll'
                                }}
                            >
                                {mapHistory()}
                            </div>
                        </ComplaintInfo>
                    )}
                </div>
            </Row>

            {complaint.status === ReclamacaoStatus.INFORMACAO_SOLICITADA &&
                <BrButton secondary onClick={() => onUpdate(ReclamacaoStatus.INFORMACAO_SOLICITADA)}>
                    {lang.tr('responder')}
                </BrButton>
            }

            {complaint.avaliacao &&
                <Rating
                    rating={complaint.avaliacao.nota}
                    description={complaint.avaliacao.descricao} />
            }

        </BrCard>
    );
};

export default ComplaintCard;
