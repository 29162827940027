import { Icon } from '@govbr-ds/react-components';
import { useState, ComponentPropsWithoutRef, ReactNode } from 'react';

export interface CollapseProps extends ComponentPropsWithoutRef<'a'> {
    name: string;
    children: ReactNode;
}

const MenuItem = ({ name, children }: CollapseProps) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div className={`menu-folder drop-menu ${isCollapsed ? 'active' : ''}`}>
            <span
                className="menu-item clickable"
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <span className="content">{name}</span>
                <span className="support">
                    <Icon icon="fas fa-chevron-down"></Icon>
                </span>
            </span>

            <ul role="tree">{children}</ul>
        </div>
    );
};

export default MenuItem;