import { BrButton, Icon, } from '@govbr-ds/react-components';
import { MouseEventHandler } from 'react';
import Assets from '../../assets';
import lang from '../../lang';

export interface MenuHeaderProps {
    userName: string;
    userCPF: string;
    closeMenu: MouseEventHandler;
}

const thumbnail = {
    height: 70,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
};

const MenuHeader = ({ userName, userCPF, closeMenu }: MenuHeaderProps) => (
    <div className="menu-header" style={{ display: 'block' }}>
        <div className="menu-close">
            <BrButton
                className="br-button circle"
                aria-label="Fechar o menu"
                data-dismiss="menu"
                onClick={closeMenu}
            >
                <Icon icon="fas fa-times" />
            </BrButton>
            <center>
                <img style={thumbnail} src={Assets.SERPRO_LGPD_HORIZONTAL} alt={lang.tr('lgpd_logo')} />
                <br />
                {userName && <p style={{ fontWeight: 'bold', color: '#0c326f' }}>{lang.tr('hello')}, {userName}!</p>}
            </center>
        </div>
    </div>
);

export default MenuHeader;
