import { Consentimento } from '../../service/consentimentosService';
import ConsentItem from './ConsentItem';
import { ConsultaConsentTitular } from '../../types/dtos';
import PaginatedList from '../../components/PaginatedList';

interface ConsentimentosMenuProps {
    consentimentos: ConsultaConsentTitular[];
    selectedConsentimento: Optional<Consentimento>;
    setConsentimento: (arg0: Consentimento) => void;
}

const ConsentsMenu = ({
    consentimentos,
    selectedConsentimento,
    setConsentimento
}: ConsentimentosMenuProps) => {
    const consentBuilder = (consentimento: Consentimento) => (
        <ConsentItem
            key={consentimento.id.toString()}
            consentimento={consentimento}
            selectedConsentimento={selectedConsentimento}
            setConsentimento={setConsentimento}
        />
    );

    return (
        <PaginatedList
            items={consentimentos}
            selectedItem={selectedConsentimento}
            setSelectedItem={setConsentimento}
            itemBuilder={consentBuilder}
        />
    );
};

export default ConsentsMenu;
