import { AvaliacaoRequisicao, CancelamentoRequisicao, Controlador, DevolucaoRequisicao, MotivoCancelamento, NovaRequisicaoDTO, PdfData, Requisicao, SumarioRequisicaoTitular, TipoRequisicao } from '../types/dtos';
import api from '../api';
import { HttpResponse } from '../api/types';

const requisicoesService = {

    async getRequisicoesSumario(): Promise<Optional<SumarioRequisicaoTitular>> {
        let resp: HttpResponse = await api.getSumarioRequisicoes();
        if (resp.success) {
            return resp.data as SumarioRequisicaoTitular;
        }
        return undefined
    },

    async getRequisicoes(): Promise<Requisicao[]> {
        let resp: HttpResponse = await api.getRequisicoes();
        if (resp.success) {
            return resp.data as Requisicao[];
        }
        return []
    },

    async getControladores(): Promise<Optional<Controlador[]>> {
        let resp: HttpResponse = await api.getReqControladores();
        if (resp.success) {
            return resp.data as Controlador[];
        }
        return undefined
    },

    async getTiposRequisicao(): Promise<TipoRequisicao[]> {
        let resp: HttpResponse = await api.getTiposRequisicao();
        if (resp.success) {
            return resp.data as TipoRequisicao[];
        }
        return []
    },

    async novaRequisicao(ctrlId: string, typeId: string, detail: string): Promise<HttpResponse> {
        let params: NovaRequisicaoDTO = {
            idControlador: ctrlId,
            idTipoRequisicao: typeId,
            detalhe: detail
        }
        return await api.novaRequisicao(params);
    },

    async downloadAnexoHistorico(idReq: string, idHist: string): Promise<boolean> {
        let resp: HttpResponse = await api.downloadAnexoHistReq(idReq, idHist);
        if (resp.success) {
            let pdfData = resp.data as PdfData

            var element = document.createElement('a');
            element.href = pdfData.content;
            element.download = pdfData.fileName;

            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        return resp.success;
    },

    async downloadPdf(idRequisicao: string, number: string): Promise<boolean> {
        let resp: HttpResponse = await api.downloadPdfRequisicao(idRequisicao);
        if (resp.success && resp.data) {
            try {
                var FileSaver = require('file-saver');
                var blob = new Blob([resp.data as string], {type: "application/pdf"});
                FileSaver.saveAs(blob, `requisicao_${number}.pdf`);
                return true;
            } catch (err) {
                return false;
            }
        }
        return false;
    },

    async avaliar(id: string, descricao: string, nota: number): Promise<boolean> {
        let params: AvaliacaoRequisicao = {
            idRequisicao: id,
            descricao: descricao,
            nota: nota
        }
        let resp: HttpResponse = await api.avaliarRequisicao(params);
        return resp.success
    },

    async cancelar(id: string, motivoId: string, outro: Nullable<string>): Promise<boolean> {
        let params: CancelamentoRequisicao = {
            idMotivoCancelamento: motivoId,
            idRequisicao: id,
            outroMotivoCancelamento: outro
        }
        let resp: HttpResponse = await api.cancelarRequisicao(params);
        return resp.success
    },

    async responder(id: string, detalhe: string): Promise<boolean> {
        let params: DevolucaoRequisicao = {
            idRequisicao: id,
            detalhe: detalhe
        }
        let resp: HttpResponse = await api.devolverRequisicao(params);
        return resp.success
    },

    async motivosCancelamento(): Promise<MotivoCancelamento[]> {
        let resp: HttpResponse = await api.getMotivosCancelamento();
        if (resp.success) {
            return resp.data as MotivoCancelamento[]
        }
        return []
    },
};

export default requisicoesService;