import '../../style/menu.css';
import { MenuItem, MenuItemProps } from './MenuItem';
import MenuHeader from './MenuHeader';
import { useEffect, useState } from 'react';
import SessionCountdown from '../SessionCountdown';
import ExitButton from '../ExitButton';
import { Container } from '@govbr-ds/react-components';

export interface MenuProps {
    userName: string;
    userCPF: string;
    isActive: boolean;
    onClose: Function;
    menuItems?: MenuItemProps[];
}

const MenuBody = (props: { menuItems: MenuItemProps[] }) => (
    <nav className="menu-body" role="tree">
        {props.menuItems.map((props: MenuItemProps, index) => (
            <MenuItem {...props} key={`menu-item-${index}`} />
        ))}
    </nav>
);

const Menu = (props: MenuProps) => {
    const defaultMenuClassName = 'br-menu';
    const defaultPanelClassName = 'menu-panel shadow-lg-right';
    const transitionInterval = 150;

    const [minHeight, setMinHeight] = useState(document.body.clientHeight);
    const [menuClassName, setMenuClassName] = useState(defaultMenuClassName);
    const [panelClassName, setPanelClassName] = useState(defaultPanelClassName);

    const getClassName = (defaultName: string, isActive: boolean) =>
        isActive ? defaultName + ' active' : defaultName;

    const closeMenu = () => {
        setPanelClassName(getClassName(defaultPanelClassName, false));
        setTimeout(props.onClose, transitionInterval);
    };

    const handleResize = () => {
        setMinHeight(document.body.clientHeight);
    };

    useEffect(() => {
        setMenuClassName(getClassName(defaultMenuClassName, props.isActive));

        if (props.isActive) {
            handleResize();
            window.addEventListener('resize', handleResize);
        }
        return () => window.removeEventListener('resize', handleResize);
    }, [props.isActive]);

    useEffect(() => {
        setPanelClassName(getClassName(defaultPanelClassName, props.isActive));
    }, [menuClassName]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={menuClassName}>
            <div className="menu-container pushable">
                <div
                    className={panelClassName}
                    style={{ minHeight: `${minHeight}px` }}
                >
                    <MenuHeader {...props} closeMenu={closeMenu} />
                    <MenuBody menuItems={props.menuItems || []} />

                    <div className="menu-footer">
                        <Container
                            padding={2}
                            alignItems="center"
                            column={true}
                            flex={true}
                        >
                            <SessionCountdown className='mb-1' />
                            <ExitButton />
                        </Container>
                    </div>
                </div>
                <div
                    className="menu-scrim"
                    style={{ position: 'fixed' }}
                    data-dismiss="menu"
                    tabIndex={0}
                    onClick={closeMenu}
                ></div>
            </div>
        </div>
    );
};

export default Menu;
