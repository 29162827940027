import { Icon } from '@govbr-ds/react-components';
import lang from '../lang';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../context/useAuth';
import { jwtDecode } from 'jwt-decode';

interface Props extends React.ComponentPropsWithoutRef<'span'> {}

const SessionCountdown = ({ className }: Props) => {
    const { authInfo } = useAuthContext();
    const [expDate, setExpDate] = useState<Optional<number>>();
    const [remainingTime, setRemainingTime] = useState<Optional<string>>();

    useEffect(() => {
        if (authInfo?.token) {
            const decoded = jwtDecode(authInfo.token);
            if (decoded?.exp) {
                setExpDate(decoded.exp * 1000);
            }
        }
    }, [authInfo]);

    useEffect(() => {
        updateRemainingTime();
    }, [expDate]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateRemainingTime = () => {
        if (expDate === undefined) {
            return;
        }

        const diffMilli = expDate - Date.now();

        const SECOND = 1000;
        const MINUTE = 60 * SECOND;
        const HOUR = 60 * MINUTE;

        let text = '';

        if (diffMilli > HOUR) {
            const hours = Math.floor(diffMilli / HOUR);
            const minutes = Math.floor((diffMilli - hours * HOUR) / MINUTE);

            text = lang.tr('sessao_hora_minuto', {
                hours: hours.toString(),
                min: minutes.toString()
            });

            setTimeout(updateRemainingTime, MINUTE);
        } else if (diffMilli > 0) {
            const minutes = Math.floor(diffMilli / MINUTE);
            const seconds = Math.floor((diffMilli - minutes * MINUTE) / SECOND);

            text = lang.tr('sessao_minuto_seg', {
                min: minutes.toString(),
                sec: seconds.toString()
            });

            setTimeout(updateRemainingTime, SECOND);
        } else {
            text = lang.tr('sessao_encerrada');
        }
        setRemainingTime(text);
    };

    if (remainingTime) {
        return (
            <span className={className}>
                <Icon icon="fa fa-clock" />
                {remainingTime}
            </span>
        );
    }
    return <></>;
};

export default SessionCountdown;
