import { BrButton, Icon } from '@govbr-ds/react-components';
import { MouseEventHandler } from 'react';
import { Breadcrumb } from '../types/breadcrumb';
import { usePageContext } from '../context/pageContext';
import { Colors } from '../types/colors';

export interface DefaultBreadcrumbProps {
    crumbs: Breadcrumb[];
}

const crumbStyle = {
    cursor: 'pointer',
    fontSize: 'medium',
    color: Colors.link,
};

const HomeCrumb = (props: { title: string; onClick: MouseEventHandler }) => (
    <li style={crumbStyle} className="crumb home">
        <BrButton circle={true} onClick={props.onClick}>
            <span className="sr-only">{props.title}</span>
            <Icon icon="fas fa-home" />
        </BrButton>
    </li>
);

const PageCrumb = (props: { title: string; onClick: MouseEventHandler }) => (
    <li style={crumbStyle} className="crumb">
        <Icon icon="fas fa-chevron-right icon" />
        <span style={{marginLeft: '10px'}} onClick={props.onClick}>{props.title}</span>
    </li>
);

export const DefaultBreadcrumb = (props: DefaultBreadcrumbProps) => {
    const { openPage } = usePageContext();

    const buildCrumb = (crumb: Breadcrumb, index: number) => {
        return crumb.isHome ? (
            <HomeCrumb
                title={crumb.title}
                onClick={() => openPage(crumb.page, { replace: true })}
                key={index}
            />
        ) : (
            <PageCrumb
                title={crumb.title}
                onClick={() => openPage(crumb.page, { replace: true })}
                key={index}
            />
        );
    };

    return (
        <nav className="br-breadcrumb" aria-label="Breadcrumbs">
            <ol className="crumb-list">
                {props.crumbs.map(buildCrumb)}
            </ol>
        </nav>
    );
};

export default DefaultBreadcrumb;
