import i18next from 'i18next';
import * as pt_br from './translations/pt-br.json';

export const defaultNS = 'translate'; // Default name space

export enum Lang {
    pt_BR = 'pt_BR',
    // en = 'en_US',
    // es = 'es',
}

i18next.init({
    lng: Lang.pt_BR,
    fallbackLng: Lang.pt_BR,
    resources: {
        pt_BR: {
            translate: pt_br,
        },
    },
});

export default i18next;