// @TODO

import { ConsultaConsentTitular, SumarioConsentimentoTitular } from '../types/dtos';
import api from '../api';
import { StatusConsentimento } from '../types/enums';

export type Consentimento = ConsultaConsentTitular;

interface UpdateStatusParams {
    idConsentimento: string;
    status: StatusConsentimento;
}

const consentimentoService = {
    async getConsentimentos(): Promise<ConsultaConsentTitular[]> {
        let resp = await api.getConsentimentos();
        if (resp.success) {
            return resp.data as ConsultaConsentTitular[];
        }
        return []
    },

    async updateStatus(id: string, status: StatusConsentimento): Promise<boolean> {
        let params: UpdateStatusParams = {
            idConsentimento: id,
            status: status
        }
        let result = await api.updateConsentimento(params)
        return result.success
    },

    async getConsentimentosSumario(): Promise<Optional<SumarioConsentimentoTitular>> {
        let resp = await api.getSumarioConsentimentos();
        if (resp.success) {
            return resp.data as SumarioConsentimentoTitular;
        }
        return undefined
    },
};

export default consentimentoService;