import { BrColors } from '../../types/colors';
import { StatusConsentimento } from '../../types/enums';
import ConsentimentosPage from './ConsentimentosPage';

export const getTagColor = (status: string) => {
    switch (status) {
        case StatusConsentimento.ATIVO:
            return BrColors.success;
        case StatusConsentimento.PENDENTE:
            return BrColors.warning;
        case StatusConsentimento.REVOGADO:
            return BrColors.support_04;
        case StatusConsentimento.NEGADO:
            return BrColors.danger;
        case StatusConsentimento.EXPIRADO:
        default:
            return BrColors.secondary_07;
    }
};

export default ConsentimentosPage;
