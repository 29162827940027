import { ChangeEvent, useEffect, useState } from 'react';
import { Page } from '../../types/page';
import PageComponent from "../../components/PageComponent";
import lang from '../../lang';
import TextMessage from '../../components/TextMessage';
import {
    Container,
    BrSelect,
    BrButton,
    BrTextarea
} from '@govbr-ds/react-components';
import requisicoesService from '../../service/requisicoesService';
import { Controlador, TipoRequisicao } from '../../types/dtos';
import { usePageContext } from '../../context/pageContext';
import stringUtils from '../../utils/stringUtils';
import popup, { PopupMessage } from '../../common/popup';
import MandatoryField from '../../components/MandatoryField';

interface Validation {
    controller?: Optional<boolean>,
    requestType?: Optional<boolean>,
    details?: Optional<boolean>,
}

const NovaRequisicaoPage = () => {
    const { openPage, setShowPopup } = usePageContext();

    const [controllerOptions, setControllerOptions] = useState<string[]>([]);
    const [controllers, setControllers] = useState<Controlador[]>([])
    const [controller, setController] = useState<Optional<Controlador>>();
    const [requestTypes, setRequestTypes] = useState<TipoRequisicao[]>([]);
    const [requestType, setRequestType] = useState<Optional<TipoRequisicao>>();
    const [requestTypeOptions, setRequestTypeOptions] = useState<string[]>([]);
    const [details, setDetails] = useState<Optional<string>>();
    const [validation, setValidation] = useState<Validation>({
        controller: undefined,
        requestType: undefined,
        details: undefined,
    });

    useEffect(() => {
        fetchControllers()
        fetchRequestTypes()
    }, [])

    useEffect(() => {

    }, [controllerOptions, requestTypes, validation])

    // ---------- Service ----------

    const fetchControllers = async () => {
        requisicoesService.getControladores().then((resp: Optional<Controlador[]>) => {
            if (resp) {
                setControllers(resp)
                setControllerOptions(resp.map(c => c.nome))
            }
        })
    }

    const fetchRequestTypes = async () => {
        requisicoesService.getTiposRequisicao().then((resp) => {
            setRequestTypes(resp)
            setRequestTypeOptions(resp.map(c => c.nome))
        })
    }

    const sendRequest = () => {
        if (validate()) {
            let ctrlId = controller!.id;
            let typeId = requestType!.id;
            requisicoesService.novaRequisicao(ctrlId, typeId, details!).then((resp) => {
                let popupMsg: PopupMessage = resp.success
                    ? popup.successConfig(lang.tr('cria_requisicao_ok'))
                    : popup.errorConfig(lang.tr('cria_requisicao_erro'))
                setShowPopup(popupMsg)

                if (resp.success) {
                    openPage(Page.REQUISICOES, { replace: true })
                }
            })
        }
    }

    // ---------- Data handling ----------

    const onControllerSelected = (value: string) => {
        let filter = controllers.filter(c => c.nome === value);
        let valid: boolean = false;
        if (filter.length > 0) {
            setController(filter[0]);
            valid = true;
        }
        setValidation({ ...validation, controller: valid })
    }

    const onRequestTypeSelected = (value: string) => {
        let filter = requestTypes.filter(c => c.nome === value);
        let valid: boolean = false;
        if (filter.length > 0) {
            setRequestType(filter[0]);
            valid = true;
        }
        setValidation({ ...validation, requestType: valid })
    }

    const onDetailsChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let text = event.target.value
        setDetails(text)
        setValidation({ ...validation, details: !stringUtils.isNullOrEmpty(text) })
    }

    const validate = () => {
        let check = {
            controller: controller !== undefined,
            requestType: requestType !== undefined,
            details: !stringUtils.isNullOrEmpty(details),
        }
        setValidation(check);
        return check.controller && check.requestType && check.details
    }

    // ---------- Render ----------

    return (
        <PageComponent page={Page.NOVA_REQUISICAO}>

            <Container className="layout layout-side contenerCentral">
                <TextMessage
                    type="info"
                    message={lang.tr('nova_requisicao_privacidade')}
                />

                <MandatoryField validate={validation.controller === false}>
                    <BrSelect
                        label={lang.tr('nova_requisicao_org')}
                        placeholder={lang.tr('nova_requisicao_org_place')}
                        name={lang.tr('nova_requisicao_org_place')}
                        options={controllerOptions}
                        value={controller?.nome ?? ''}
                        onClick={({ currentTarget: { value } }) => { onControllerSelected(value) }}
                    />
                </MandatoryField>

                <MandatoryField validate={validation.requestType === false}>
                    <BrSelect
                        label={lang.tr('nova_requisicao_req')}
                        placeholder={lang.tr('nova_requisicao_req_place')}
                        name={lang.tr('nova_requisicao_req_place')}
                        options={requestTypeOptions}
                        value={requestType?.nome ?? ''}
                        onClick={({ currentTarget: { value } }) => { onRequestTypeSelected(value) }}
                    />
                </MandatoryField>

                <MandatoryField validate={validation.details === false}>
                    <BrTextarea id="textarea-id1"
                        label={lang.tr('nova_requisicao_title_text_area')}
                        placeholder={lang.tr('nova_requisicao_title_text_area_place')}
                        onChange={(evt) => onDetailsChanged(evt)}
                    ></BrTextarea>
                </MandatoryField>

                <BrButton primary type='submit'
                    onClick={sendRequest}>{lang.tr('enviar')}
                </BrButton>
            </Container>

        </PageComponent>
    )
}

export default NovaRequisicaoPage;