import { BrTag, Col, Typography } from '@govbr-ds/react-components';
import { getTagColor } from '.';
import { Reclamacao } from '../../types/dtos';
import lang from '../../lang';

const ComplaintItem = (props: {
    reclamacao: Reclamacao;
    selected: Optional<Reclamacao>;
    key: string;
    setReclamacao: Function;
}) => {
    const className = 'menu-item divider clickable';
    const selectedClassName = className + ' selected';
    const isSelected = props.selected === props.reclamacao;

    return (
        <span
            className={isSelected ? selectedClassName : className}
            style={{ justifyContent: 'space-between' }}
            onClick={() => {
                props.setReclamacao(props.reclamacao);
            }}
        >
            <div>
                <Col>
                    <Typography weight="semi-bold" size="up-01">
                        {props.reclamacao.controlador.nome}
                    </Typography>

                    <Typography size="base">{props.reclamacao.numero}</Typography>
                </Col>
            </div>

            <div>
                <BrTag
                    size='small'
                    color={getTagColor(props.reclamacao.status)}
                    value={lang.tr(props.reclamacao.status)}
                    type="text"
                />
            </div>
        </span>
    );
};

export default ComplaintItem;
