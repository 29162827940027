import { Container, Typography } from '@govbr-ds/react-components';
import Header from './Header';
import Footer from './Footer';
import DefaultMenu from './DefaultMenu';
import { useEffect, useState } from 'react';
import AppBar from './AppBar';
import { PageContextType, usePageContext } from '../context/pageContext';
import { SessionInfo } from '../types/dtos';
import storage from '../context/storage';
import { Page } from '../types/page';
import AppRoutes from '../api/app-routes';

interface PageProps {
    page: Page;
    nav?: React.ReactNode;
    children: React.ReactNode;
    fullPage?: boolean;
    reset?: Optional<boolean>;
}

const PageComponent = ({ children, page, fullPage, reset }: PageProps) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const { openPage } = usePageContext() as PageContextType;
    const [sessionInfo, setSessionInfo] = useState<SessionInfo>();

    useEffect(() => {
        if (reset) {
            openPage(Page.HOME, { replace: true });
        } else {
            let info = storage.fetch(storage.SESSION_INFO);
            if (info) {
                try {
                    let obj: SessionInfo = JSON.parse(info) as SessionInfo;
                    setSessionInfo(obj);
                } catch (error) {}
            } else {
                openPage(Page.SPLASH, { replace: true });
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header />
            <DefaultMenu
                userName={sessionInfo?.name ?? ''}
                userCPF={sessionInfo?.cpf ?? ''}
                isActive={activeMenu}
                onClose={() => setActiveMenu(false)}
            ></DefaultMenu>

            <AppBar openMenu={() => setActiveMenu(true)} page={page} />

            {fullPage ? (
                children
            ) : (
                <Container className="layout layout-side contenerCentral">
                    <Typography
                        htmlElement="h2"
                        weight="semi-bold"
                        color="primary-default"
                        margin={1}
                    >
                        {AppRoutes.getTitle(page)}
                    </Typography>
                    {children}
                </Container>
            )}

            <Footer />
        </>
    );
};

export default PageComponent;
