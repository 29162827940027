import {
    BrButton,
    BrCard,
    BrItem,
    BrTag,
    Container,
    Icon,
    Row,
    Typography
} from '@govbr-ds/react-components';
import lang from '../../lang';
import { getTagColor } from '.';
import { HistoricoRequisicao, Requisicao } from '../../types/dtos';
import { ReactNode, useEffect } from 'react';
import { StatusRequisicao } from '../../types/enums';
import requisicoesService from '../../service/requisicoesService';
import Rating from '../../components/Rating';
import '../../style/info.css';
import stringUtils from '../../utils/stringUtils';
import popup, { PopupMessage } from '../../common/popup';
import { usePageContext } from '../../context/pageContext';

// ---------- Interface ----------

interface InfoProps {
    label: string;
    value?: string;
    icon?: string;
    children?: ReactNode;
}

interface DownloadButtonProps {
    title: string;
    downloadPdfContent?: () => Promise<boolean>
    downloadPdfFile?: () => Promise<boolean>
}

interface FooterProps {
    requisicao: Requisicao;
    onUpdate: (status: StatusRequisicao) => void;
}

interface CardProps {
    requisicao: Requisicao;
    onUpdate: (status: StatusRequisicao) => void;
}

// ------------ Render ------------

const RequisicaoInfo = ({ label, value, icon, children }: InfoProps) => {
    return (
        <div className={'info-panel'}>
            <Typography htmlElement="p" weight="bold" className="mb-1n">
                {label}
            </Typography>
            {icon && <Icon icon={icon} />}
            {value ? <span className='info-text'>{value}</span> : children}
            <br />
        </div>
    );
};

// ----------------------------

const DownloadButton = ({ title, downloadPdfContent, downloadPdfFile }: DownloadButtonProps) => {
    const { setShowPopup } = usePageContext();

    const handleDownload = async () => {
        let success: boolean = false;
        if (downloadPdfContent) {
            success = await downloadPdfContent();
        } else if (downloadPdfFile) {
            success = await downloadPdfFile();
        }

        if (!success) {
            let popupMsg: PopupMessage = popup.errorConfig(lang.tr("download_error"))
            setShowPopup(popupMsg)
        }
    }

    return (
        <BrButton secondary onClick={handleDownload}>
            {title}
        </BrButton>
    )
}

// ----------------------------

const CardFooter = ({ requisicao, onUpdate }: FooterProps) => {

    const actions = () => {
        switch (requisicao.status) {
            case StatusRequisicao.CONCLUIDO:
                return (
                    <Row className="card-footer" justifyContent="center">
                        <DownloadButton
                            title={lang.tr('download_pdf')}
                            downloadPdfFile={
                                () => requisicoesService.downloadPdf(requisicao.id, requisicao.numero)
                            }
                        />
                    </Row>
                );

            case StatusRequisicao.PENDENTE:
            case StatusRequisicao.EM_ATENDIMENTO:
            case StatusRequisicao.ATENDIDO:
            case StatusRequisicao.NAO_ATENDIDO:
            case StatusRequisicao.INFORMACAO_ENVIADA:
                return (
                    <Row className="card-footer" justifyContent="around">
                        <BrButton secondary onClick={() => onUpdate(StatusRequisicao.CANCELADO)}>
                            {lang.tr('cancelar')}
                        </BrButton>
                    </Row>
                );
            case StatusRequisicao.RESPONDIDO:
            case StatusRequisicao.INFORMACAO_SOLICITADA:
                return (
                    <Row className="card-footer" justifyContent="around">
                        <BrButton secondary onClick={() => onUpdate(StatusRequisicao.INFORMACAO_ENVIADA)}>
                            {lang.tr('responder')}
                        </BrButton>
                        <BrButton secondary onClick={() => onUpdate(StatusRequisicao.CANCELADO)}>
                            {lang.tr('cancelar')}
                        </BrButton>
                    </Row>
                );
            default:
                return <></>;
        }
    }

    return (
        <>
            {actions()}
            {requisicao.avaliacao &&
                <Rating rating={requisicao.avaliacao.nota} description={requisicao.avaliacao.descricao} />
            }
        </>
    )

};

// ----------------------------

const RequisicaoCard = ({ requisicao, onUpdate }: CardProps) => {

    useEffect(() => {

    }, [requisicao])

    const orderedHistory = () => {
        return requisicao.historico ?
            requisicao.historico.sort((a, b) => b.dataHora.localeCompare(a.dataHora)) : [];
    }

    const mapHistory = () =>
        orderedHistory().map(
            (item: HistoricoRequisicao, index: number) => {
                return (
                    <BrItem
                        className="br-item"
                        divider={true}
                        key={index}
                        density="media"
                    >
                        <Container>
                            <Row justifyContent="between">
                                <Typography htmlElement="span" weight="semi-bold">
                                    {item.labelText}
                                </Typography>
                                <BrTag size={'small'} style={{ fontSize: 12 }}
                                    color={getTagColor(item.status)}
                                    value={lang.tr(item.status)}
                                    type="text"
                                />
                            </Row>
                            <br />
                            <Typography htmlElement="p" className="mb-1n">
                                {item.text}
                                <br />
                                {stringUtils.formatDate(item.dataHora)}
                            </Typography>

                            {item.anexoResposta &&
                                <>
                                    <br />
                                    <DownloadButton
                                        title={lang.tr('download_anexo')}
                                        downloadPdfContent={
                                            () => requisicoesService.downloadAnexoHistorico(requisicao.id, item.id)
                                        }
                                    />
                                </>
                            }
                        </Container>
                    </BrItem>
                );
            }
        );

    return (
        <BrCard style={{ padding: '1rem' }}>
            <Row className="card-header" justifyContent="between">
                <Typography weight="bold" size="up-02">
                    {requisicao.controlador.nome}
                </Typography>

                <BrTag
                    color={getTagColor(requisicao.status)}
                    value={lang.tr(requisicao.status)}
                    type="text"
                />
            </Row>
            <Row className="card-content">
                <div style={{ width: '100%' }}>
                    <RequisicaoInfo
                        label={lang.tr('numero')}
                        value={requisicao.numero}
                    />

                    <RequisicaoInfo
                        label={lang.tr('tipo_requisicao')}
                        value={requisicao.tipoRequisicao.nome}
                    />

                    <RequisicaoInfo
                        label={lang.tr('detalhe')}
                        value={requisicao.detalhe}
                    />

                    <RequisicaoInfo
                        label={lang.tr('data_solicitacao')}
                        value={stringUtils.formatDate(requisicao.dataSolicitacao)}
                        icon="fa fa-calendar-alt"
                    />

                    {requisicao.dataConclusao &&
                        <RequisicaoInfo
                            label={lang.tr('data_conclusao')}
                            value={stringUtils.formatDate(requisicao.dataConclusao)}
                            icon="fa fa-calendar-alt"
                        />
                    }

                    {requisicao.motivoCancelamento &&
                        <RequisicaoInfo
                            label={lang.tr('motivo_cancelamento')}
                            value={requisicao.outroMotivoCancelamento ?? requisicao.motivoCancelamento.nome}
                        />
                    }

                    {requisicao.historico.length > 0 &&
                        <RequisicaoInfo label={lang.tr('historico_atualizacoes')}>
                            <div className="br-list shadow-sm-up"
                                style={{ maxHeight: '30rem', overflow: 'scroll' }}>
                                {mapHistory()}
                            </div>
                        </RequisicaoInfo>
                    }
                </div>
            </Row>

            <CardFooter
                requisicao={requisicao}
                onUpdate={onUpdate}
            />
        </BrCard>
    );
};

export default RequisicaoCard;
