import stringUtils from '../../utils/stringUtils';
import {
    EllipsisButton,
    NextPageButton,
    PageButton,
    PreviousPageButton
} from './PageButtons';

interface PaginationProps {
    currentPage: number;
    setCurrentPage: Function;
    total: number;
    maxPagesShown?: number;
}

const buildPageNumbers = (startPage: number, endPage: number) => {
    const pageNumbers = [];

    for (let i = startPage; i < endPage; i++) {
        pageNumbers.push(i);
    }

    return pageNumbers;
};

const getVisiblePages = (
    maxPagesShown: number,
    total: number,
    currentPage: number
) => {
    const visible_pages = [...Array(maxPagesShown)];

    visible_pages[0] = 1;
    visible_pages[visible_pages.length - 1] = total;

    if (maxPagesShown <= 2) {
        return visible_pages;
    }

    return definePageNumbers(visible_pages, maxPagesShown, total, currentPage);
};

const definePageNumbers = (
    visiblePages: number[],
    maxPagesShown: number,
    total: number,
    currentPage: number
) => {
    const centerIndex = Math.floor(maxPagesShown / 2);

    let prevPage = 0;

    return visiblePages.map((page, index) => {
        if (page !== undefined) {
            prevPage = page;
            return page;
        }

        let pageNumber = currentPage - (centerIndex - index);

        if (currentPage + index >= total) {
            return total - (maxPagesShown - 1) + index;
        }

        if (pageNumber <= prevPage) {
            prevPage++;
            return prevPage;
        }

        return pageNumber;
    });
};

const Pagination = ({
    currentPage,
    setCurrentPage,
    total,
    maxPagesShown
}: PaginationProps) => {
    const prevPage = () => setCurrentPage(Math.max(currentPage - 1, 1));
    const nextPage = () => setCurrentPage(Math.min(currentPage + 1, total));

    const buildPageButtons = () => {
        maxPagesShown = maxPagesShown || total;

        const visiblePages = getVisiblePages(maxPagesShown, total, currentPage);

        let prevPageNumber: Optional<number>;

        return visiblePages.map((pageNumber, index) => {
            let pageNumbers = [] as number[];

            if (prevPageNumber && pageNumber > prevPageNumber + 1) {
                pageNumbers = buildPageNumbers(prevPageNumber + 1, pageNumber);
            }

            prevPageNumber = pageNumber;

            return (
                <div
                    style={{ display: 'flex', flexDirection: 'row' }}
                    key={stringUtils.uniqueId()}
                >
                    {pageNumbers.length > 0 && (
                        <EllipsisButton
                            key={`el-${index}`}
                            pageNumbers={pageNumbers}
                            setPage={setCurrentPage}
                        />
                    )}
                    <PageButton
                        key={`btn-${index}`}
                        pageNumber={pageNumber}
                        isSelected={currentPage === pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                    />
                </div>
            );
        });
    };

    return (
        <nav className="br-pagination" key={stringUtils.uniqueId()}>
            <ul>
                <PreviousPageButton onClick={prevPage} />
                {buildPageButtons()}
                <NextPageButton onClick={nextPage} />
            </ul>
        </nav>
    );
};

export default Pagination;
