import { BrModal, BrTextarea, Typography } from '@govbr-ds/react-components';
import { Requisicao } from '../../types/dtos';
import lang from '../../lang';
import { ChangeEvent, useEffect, useState } from 'react';
import requisicoesService from '../../service/requisicoesService';
import { usePageContext } from '../../context/pageContext';
import popup, { PopupMessage } from '../../common/popup';
import '../../style/modal.css'
import stringUtils from '../../utils/stringUtils';

const ModalTratamentoReq = (props: { requisicao: Requisicao, onDismiss: (refresh: boolean) => void }) => {
    const { setShowPopup } = usePageContext();

    const [requisicao] = useState<Requisicao>(props.requisicao)
    const [resposta, setResposta] = useState<Optional<string>>()
    const [validResp, setValidResp] = useState<boolean>();

    useEffect(() => {

    }, [resposta, validResp])

    const onDescriptionChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let text = event.target.value
        setResposta(text)
        setValidResp(!stringUtils.isNullOrEmpty(text))
    }

    const submit = () => {
        if (!stringUtils.isNullOrEmpty(resposta)) {
            requisicoesService.responder(requisicao.id, resposta!).then((resp) => {
                let popupMsg: PopupMessage = resp
                    ? popup.successConfig(lang.tr('requisicao_respondida_sucesso'))
                    : popup.errorConfig(lang.tr('requisicao_respondida_erro'))
                setShowPopup(popupMsg)
                props.onDismiss(true)
            })
        }
    }

    return (
        <BrModal title={lang.tr('tratar_requisicao')}
            minWidth={500}
            isOpen={true}
            secondaryAction={{ label: lang.tr('cancelar'), action: () => props.onDismiss(false) }}
            primaryAction={{ label: lang.tr('confirmar'), disabled: !validResp, action: () => submit() }}
            onClose={() => props.onDismiss(false)}>
            {
                <div className='modal-content'>
                    <br />
                    <Typography size="up-01">
                        {lang.tr('requisicao_nro')}
                    </Typography>
                    <Typography weight="semi-bold" size="up-01">
                        {requisicao.numero}
                    </Typography>
                    <br />
                    <BrTextarea id="textarea-id1"
                        label={lang.tr('descreva_resposta')}
                        placeholder={lang.tr('descreva_resposta')}
                        onChange={(evt) => onDescriptionChanged(evt)}
                    ></BrTextarea>
                </div>
            }
        </BrModal>
    );
};

export default ModalTratamentoReq;
