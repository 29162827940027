import { BrButton } from "@govbr-ds/react-components"
import lang from "../lang"
import Assets from "../assets";
import { useAuthContext } from "../context/useAuth";
import "../style/splash.css";
import Toast from "../components/Toast";
import { useEffect, useState } from "react";

const SplashPage = () => {
    const { govBREndpoint } = useAuthContext();
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {

    }, [loading])

    const style = {
        backgroundImage: `url(${Assets.PDC_BACKGROUND})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }

    const center = {
        display: 'grid',
        margin: 'auto',
    }

    const onLogin = () => {
        setLoading(true)
        govBREndpoint()
    }

    return <div className="App" style={style}>
        <div style={center}>
            <img className="splash-banner" src={Assets.SERPRO_LGPD_WHITE_BANNER} alt={lang.tr('lgpd')} />
            <div className="splash-btn">
                <BrButton secondary loading={loading} onClick={onLogin}>
                    {lang.tr('acesse_govbr')}
                </BrButton>
                <Toast/>
            </div>
        </div>
    </div>
}

export default SplashPage;